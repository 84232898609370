import { NavLink } from "react-router-dom";
type SettingSideLinkProps = {
  text: string;
  icon: string;
  href: string;
  isActive: boolean;
};
const SettingSideLink = ({
  text,
  icon,
  href,
  isActive,
}: SettingSideLinkProps) => {
  return (
    <NavLink className="w-[90%] mb-3 my-2" to={href}>
      <li
        className={`w-full flex justify-start items-center  py-3 hover:rounded-tl-[100px]  hover:rounded-bl-[100px]   ${
          isActive
            ? "bg-white rounded-tl-[100px] rounded-bl-[100px] text-[#102448] ml-2"
            : "text-white hover:bg-[#00000044]"
        }`}
      >
        <div className="w-40 flex justify-evenly text-base">
          <div className="w-28 flex items-center text-sm lg:text-base ">
            <div className="material-symbols-outlined flex items-center mr-2">
              {icon}
            </div>
            {text}
          </div>
        </div>
      </li>
    </NavLink>
  );
};

export default SettingSideLink;
