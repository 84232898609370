import { useQuery } from "@tanstack/react-query";
import CardInbox from "./CardInbox";
import { getNotification } from "../../api/notification";
import Pagination from "../common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, toggleSelectedAll } from "../../store/notification";
import { RootState } from "../../app/store";

const ListInbox = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(
    (state: RootState) => state.notification.currentPage
  );
  const selectedRows = useSelector(
    (state: RootState) => state.notification.selectedRows
  );

  const queries = [];
  queries.push("take=10");
  queries.push(`page=${currentPage}`);
  const params = queries.length ? "?" + queries.join("&") : "";
  const { data } = useQuery({
    queryKey: ["notification", queries],
    queryFn: async function () {
      const result = await getNotification(params);

      return result;
    },
  });
  console.log(data, "inbox");
  const allSelected = () => {
    const allRows = data?.data?.map((item: { id: string }) => item.id) || [];
    for (const row of allRows) {
      if (!selectedRows.includes(row)) {
        return false;
      }
    }
    return true;
  };
  return (
    <>
      {data?.data?.length == 0 ? (
        <div className="w-full h-[50vh] flex flex-col gap-2 justify-center items-center">
          <img src="/noRecord.png" alt="" />
          <span className="text-[#102448]/[0.60]">No records yet</span>
        </div>
      ) : (
        <div className="w-full rounded-lg shadow-lg p-3">
          <div className="p-1 lg:p-3 flex items-center gap-2 text-[#102448]">
            <input
              type="checkbox"
              checked={allSelected()}
              onChange={() => {
                dispatch(
                  toggleSelectedAll({
                    allRows: data.data.map((item: { id: string }) => item.id),
                  })
                );
              }}
            />
            <span>Select All</span>
          </div>
          {data?.data?.map(
            (item: {
              id: string;
              sender: string;
              is_seen: boolean;
              message: string;
              createdAt: string;
              type: string;
              notif_info: {
                entity_id: string;
              };
            }) => (
              <CardInbox
                id={item.id}
                key={item.id}
                seen={item.is_seen}
                sender={item.sender}
                message={item.message}
                createdAt={item.createdAt}
                type={item.type}
                entityId={item.notif_info?.entity_id}
              />
            )
          )}
        </div>
      )}
      {data?.data ? (
        <Pagination
          onClickP={() => {
            dispatch(setCurrentPage(currentPage - 1));
          }}
          currentPage={currentPage}
          onClickN={() => {
            dispatch(setCurrentPage(currentPage + 1));
            console.log("inside onClick", currentPage);
          }}
          npage={data?.last_page}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ListInbox;
