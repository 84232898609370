import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setBroker, setDeal } from "../../../../store/deal-slice";
import {
  DealDetailMortgagedStep,
  DealDetailPHPPStep,
  DepositeMethod,
} from "../../../../types/lead";
import DescriptionField from "../../../common/Description";
import SelectField from "../../../common/Select";
import Text from "../../../common/TextField";
import UploadRemainingCheques from "./upload/RemainingChequesUpload";
import OtherFees from "./OtherFees";

const DealDetail = () => {
  const dispatch = useDispatch();
  const is_ready_with_phpp = useSelector(
    (state: RootState) => state.deal.deal.is_ready_with_phpp
  );
  const is_mortgaged = useSelector(
    (state: RootState) => state.deal.deal.is_mortgaged
  );
  let prima_broker_commission = useSelector(
    (state: RootState) => state.deal.deal.prima_broker_commission
  );
  const Select = ({
    field,
    label,
    data,
  }: {
    field: string;
    label: string;
    data: { title: string; value: string | boolean }[];
  }) => {
    return (
      <SelectField
        slice="deal"
        object="deal"
        action={setDeal}
        field={field}
        label={label}
        data={data}
      />
    );
  };

  const TextField = ({
    type,
    label,
    name,
    field,
    price,
  }: {
    type: string;
    label: string;
    name: string;
    field: string;
    price?: boolean;
  }) => {
    return (
      <Text
        slice="deal"
        object="deal"
        action={setDeal}
        type={type}
        label={label}
        name={name}
        field={field}
        price={price}
      />
    );
  };

  return (
    <div className="border rounded-lg p-3 bg-prima-light flex flex-col gap-4">
      <div className="flex flex-wrap lg:flex-nowrap justify-between">
        <div className="w-full lg:w-6/12">
          <Select
            label="Is it a ready property without PHPP ?"
            field="is_ready_with_phpp"
            data={DealDetailPHPPStep}
          />
        </div>
        {!is_ready_with_phpp && (
          <div className="w-full lg:w-5/12">
            <Select
              label="is it mortgaged or cash buyer?"
              field="is_mortgaged"
              data={DealDetailMortgagedStep}
            />
          </div>
        )}
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-between">
        <div className="w-full lg:w-6/12">
          <TextField
            type="text"
            label="Final Transaction Amount"
            name="finalTransactionAmount"
            field="final_transaction_amount"
            price={true}
          />
        </div>
        <div className="w-full lg:w-5/12">
          <TextField
            type="text"
            label="Deposit of 10%"
            name="deposit"
            field="deposit"
            price={true}
          />
        </div>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-between">
        <div className="w-full lg:w-6/12">
          <Select
            label="Deposit Method"
            field="deposit_method"
            data={DepositeMethod}
          />
        </div>
        <div className="w-full lg:w-5/12">
          <TextField
            type="text"
            label="NOC Fee"
            name="nocFee"
            field="noc_fee"
            price={true}
          />
        </div>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-between">
        <div className="w-full lg:w-6/12">
          <TextField
            type="text"
            label="Trustee Fees"
            name="trusteeFees"
            field="trustee_fees"
            price={true}
          />
        </div>
        <div className="w-full lg:w-5/12">
          <TextField
            type="text"
            label="Title Deed Fee"
            name="titleDeedFee"
            field="title_deed_fee"
            price={true}
          />
        </div>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap justify-between">
        <div className="w-full lg:w-6/12">
          <TextField
            type="number"
            label="Commission (total for the deal)"
            name="commission"
            field="commission"
            price={true}
          />
        </div>
        <div className="w-full lg:w-5/12">
          <TextField
            type="text"
            label="Commission Amount"
            name="commissionAmount"
            field="commission_amount"
            price={true}
          />
        </div>
      </div>
      <div className="flex justify-between"></div>
      <div className="flex justify-between">
        <div className="w-10/12">
          <OtherFees
            label="Other Fees"
            fieldName="other_fees"
            slice="deal"
            object="deal"
          />
        </div>
      </div>
      {!is_ready_with_phpp && is_mortgaged && (
        <div className="flex flex-wrap lg:flex-nowrap justify-between">
          <div className="w-full lg:w-6/12">
            <TextField
              type="number"
              label="Percentage Paid By Buyer"
              name="percentagePaidBuyer"
              field="percentage_paid_buyer"
            />
          </div>
          <div className="w-full lg:w-5/12">
            <TextField
              type="number"
              label="Mortgaged Percentage"
              name="mortgagedPercentage"
              field="mortgaged_percentage"
            />
          </div>
        </div>
      )}
      {is_ready_with_phpp && (
        <div className="flex flex-wrap lg:flex-nowrap justify-between">
          <div className="w-full lg:w-6/12">
            <UploadRemainingCheques />
          </div>
          <div className="w-full lg:w-5/12">
            <TextField
              type="text"
              label="Amount Paid To Seller"
              name="amountPaidSeller"
              field="amount_paid_seller"
              price={true}
            />
          </div>
        </div>
      )}
      <label className="block my-1">Commission Distribution</label>
      <div className="border rounded-lg p-3 bg-prima-light flex flex-col gap-4 mb-4">
        <div className="flex flex-wrap lg:flex-nowrap justify-between">
          <div className="w-full lg:w-5/12">
            <TextField
              type="text"
              label="Prima Company"
              name="primaCompany"
              field="prima_company"
              price={true}
            />
          </div>
          <div className="w-full lg:w-6/12">
            <TextField
              type="text"
              label="VAT 5% on Prima commission (AED)"
              name="vatOfPrimaCommission"
              field="vat_of_prima_commission"
              price={true}
            />
          </div>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap justify-between">
          <div className="w-full lg:w-5/12">
            <TextField
              type="text"
              label="Referal"
              name="referal"
              field="referal"
              price={true}
            />
          </div>
          <div className="w-full lg:w-6/12  relative">
            <TextField
              type="text"
              label="Prima Broker"
              name="primaBroker"
              field="prima_broker"
              price={true}
            />
            <div className="absolute right-0 top-0 border  flex gap-2">
              <div
                onClick={() =>
                  dispatch(setBroker((prima_broker_commission = 0.5)))
                }
                className={`border rounded bg-white text-[#102448] flex justify-center items-center cursor-pointer px-2 text-xs ${
                  prima_broker_commission === 0.5
                    ? "border border-blue-600"
                    : ""
                }`}
              >
                50%
              </div>
              <div
                onClick={() =>
                  dispatch(setBroker((prima_broker_commission = 0.6)))
                }
                className={`border rounded bg-white text-[#102448] flex justify-center items-center cursor-pointer px-2 text-xs ${
                  prima_broker_commission === 0.6
                    ? "border border-blue-600"
                    : ""
                }`}
              >
                60%
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap justify-between">
          <div className="w-full lg:w-10/12">
            <OtherFees
              label="Other agents"
              fieldName="other_agents"
              slice="deal"
              object="deal"
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <DescriptionField
          label="Comment"
          name="dealDetailComment"
          slice="deal"
          object="deal"
          field="deal_detail_comment"
          className=""
          action={setDeal}
        />
      </div>
    </div>
  );
};
export default DealDetail;
