import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetFilter,
  setRentVolumeYear,
} from "../../../../store/chart-property";
import { RootState } from "../../../../app/store";
import RefreshButton from "../../../common/RefreshButton";
import { useQueryClient } from "@tanstack/react-query";
const FilterChartRentVolume = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const rentVolumeYear = useSelector(
    (state: RootState) => state.chartproperty.filter.rentVolumeYear
  );

  const renderYearContent = (year: number) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };
  return (
    <div className="w-full flex items-end justify-between">
      <div className="flex flex-col w-2/4">
        <label className="text-[#102448]">Year</label>
        <DatePicker
          className="border p-1 rounded bg-[#102448]/[0.02] outline-none"
          onChange={(y: Date) => {
            dispatch(setRentVolumeYear(y?.toString()));
            queryClient.removeQueries({
              queryKey: ["TotalRentVolume", y.toString()],
            });
          }}
          selected={rentVolumeYear}
          renderYearContent={renderYearContent}
          showYearPicker
          dateFormat="yyyy"
        />
      </div>
      <RefreshButton
        onClick={() => {
          dispatch(resetFilter());
        }}
      />
    </div>
  );
};

export default FilterChartRentVolume;
