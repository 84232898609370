import axios from "axios";

export async function getTotalProperty(qParams?: string) {
  if (!qParams) qParams = "";
  const res = await axios.get(`/reports/totalnumberofproperty${qParams}`);
  return res.data;
}
export async function getTotalEnquiry(qParams?: string) {
  if (!qParams) qParams = "";
  const res = await axios.get(`/reports/totalnumberofenquiry${qParams}`);
  return res.data;
}
export async function getTotalCompanyCustomer(qParams?: string) {
  if (!qParams) qParams = "";
  const res = await axios.get(
    `/reports/totalnumberofcompanycustomer${qParams}`
  );

  return res.data;
}
export async function getSourceofEnquiry(qParams?: string) {
  if (!qParams) qParams = "";
  const res = await axios.get(`/reports/sourceofenquiry${qParams}`);
  return res.data;
}
export async function getTotlaRentValue(qParams?: string) {
  if (!qParams) qParams = "";
  const res = await axios.get(`/reports/totalsalerentvalue${qParams}`);
  return res.data;
}
export async function getTotalRentVolume(qParams?: string) {
  if (!qParams) qParams = "";
  const res = await axios.get(`/reports/totalrentvolume${qParams}`);

  return res.data;
}
