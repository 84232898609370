import DealDetail from "../DealDetail";
import SaveDeal from "../SaveDeal";
import DeadUpload from "../upload/DeadUpload";
import NOCUpload from "../upload/NOCUpload";

const CompletedDeal = () => {
  return (
    <div className="">
      <div className="flex flex-col gap-5 mt-5 border-y  border-slate-100 rounded-lg shadow-lg p-5 ">
        <div className="w-full mb-2">
          <NOCUpload />
        </div>
        <div className="w-full mb-2">
          <DeadUpload />
        </div>
        <label className="block my-1 text-lg text-dark-blue">Deal Detail</label>
        <DealDetail />
      </div>
      <SaveDeal />
    </div>
  );
};

export default CompletedDeal;
