import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useQuery } from "@tanstack/react-query";
import { getTotlaRentValue } from "../../../api/dashboard/reports";
import { useRef, useState } from "react";
import useClickOutSide from "../../../hooks/useClickOutSide";
import FirstRange from "./FirstRange";
import SecondRange from "./SecondRange";

const TotalLeadValue = () => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [toggleInfo, setToggleInfo] = useState(false);
  useClickOutSide(boxRef, () => {
    setToggleInfo(false);
  });
  const { start_date, end_date, compare_start_date, compare_end_date } =
    useSelector((state: RootState) => state.rentandsalecard.filter);
  const queries = [];
  if (start_date) queries.push(`start_date=${start_date}`);
  if (end_date) queries.push(`end_date=${end_date}`);
  if (compare_start_date)
    queries.push(`compare_start_date=${compare_start_date}`);
  if (compare_end_date) queries.push(`compare_end_date=${compare_end_date}`);
  const params = queries.length ? "?" + queries.join("&") : "";
  const { data, isLoading } = useQuery({
    queryKey: ["TotalRentValue", queries],
    queryFn: async () => await getTotlaRentValue(params),
  });
  if (isLoading) {
    return "";
  }

  return (
    <div className="w-full lg:w-1/2 rounded-lg border border-[#102448]/[0.20] p-3 flex flex-col gap-6 relative ">
      <div className="flex items-start justify-between">
        <h2 className="text-[#102448] font-bold">
          Total Number of <br />
          Leads
        </h2>
        {data?.growthRateForTotalNumberOfLeads && (
          <span
            className={`flex w-20 items-center  mt-2 rounded-xl border border-green-600 text-green-600 ${
              data?.growthRateForSaleAmount > 0
                ? "border-green-600 text-green-600"
                : " border-red-600 text-red-600"
            }`}
          >
            <span className="material-symbols-outlined scale-50 ">
              {data?.growthRateForTotalNumberOfLeads > 0
                ? "arrow_upward"
                : "arrow_downward"}
            </span>
            <span className="text-xs">
              {data?.growthRateForTotalNumberOfLeads}%
            </span>
          </span>
        )}
      </div>
      <p className="text-[#102448]/[0.60] font-bold">
        {parseInt(data?.currentTotalNumberOfLeads.total).toLocaleString()}
      </p>
      <span className="absolute right-1 bottom-1 w-full flex justify-end text-[#102448]">
        <span
          className="material-symbols-outlined cursor-pointer"
          onClick={() => {
            setToggleInfo(!toggleInfo);
          }}
        >
          info
        </span>
      </span>
      {toggleInfo && (
        <div className="absolute z-10 w-full  border rounded bg-[#ededed] py-1 px-2 left-0 bottom-7  flex-col gap-2 flex transition-all">
          <span className="text-[#102448] font-bold">Total No Leads</span>
          <span className="flex flex-col">
            <span className="text-[#102448] shrink-0">
              First Range :
              <span className="text-xs">
                {" "}
                <FirstRange />
              </span>
            </span>
            <span className="text-xs text-[#102448]">
              Total:
              {parseInt(data?.currentTotalNumberOfLeads.total).toLocaleString()}
              , For Rent:{data?.currentTotalNumberOfLeads.for_rent}, For Sale:
              {data?.currentTotalNumberOfLeads.for_sale}
            </span>
          </span>
          <span className="flex flex-col">
            <span className="text-[#102448] text-xs">
              Second Range :
              <span className="text-xs">
                {" "}
                {/* {compare_start_date} - {compare_end_date} */}
                <SecondRange />
              </span>
            </span>
            <span className="text-xs text-[#102448]">
              Total:
              {parseInt(
                data?.previousTotalNumberOfLeads.total
              ).toLocaleString()}{" "}
              , For Rent:
              {data?.previousTotalNumberOfLeads.for_rent}, For Sale:
              {data?.previousTotalNumberOfLeads.for_sale}
            </span>
          </span>
          <span className="text-[#102448] font-bold text-xs">
            {data?.growthRateForTotalNumberOfLeads > 0
              ? `+${data?.growthRateForTotalNumberOfLeads}`
              : data?.growthRateForTotalNumberOfLeads}
            %
          </span>
        </div>
      )}
    </div>
  );
};

export default TotalLeadValue;
