import { useQuery } from "@tanstack/react-query";
import CardInbox from "./CardInbox";
import { getNotification } from "../../api/notification";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Pagination from "../common/Pagination";
import {
  setCurrentPageReminder,
  toggleSelectedAll,
} from "../../store/notification";

const ListReminder = () => {
  const dispatch = useDispatch();
  const selectedRows = useSelector(
    (state: RootState) => state.notification.selectedRows
  );

  const currentPageReminder = useSelector(
    (state: RootState) => state.notification.currentPageReminder
  );

  const queries = [];
  queries.push("take=10");
  queries.push(`page=${currentPageReminder}`);
  queries.push(`is_reminder=true`);
  const params = queries.length ? "?" + queries.join("&") : "";
  const { data } = useQuery({
    queryKey: ["reminder", queries],
    queryFn: async function () {
      const result = await getNotification(params);

      return result;
    },
  });
  const allSelected = () => {
    const allRows = data?.data?.map((item: { id: string }) => item.id) || [];
    for (const row of allRows) {
      if (!selectedRows.includes(row)) {
        return false;
      }
    }
    return true;
  };
  return (
    <>
      {data?.data?.length == 0 ? (
        <div className="w-full h-[50vh] flex flex-col gap-2 justify-center items-center">
          <img src="/noRecord.png" alt="" />
          <span className="text-[#102448]/[0.60]">No records yet</span>
        </div>
      ) : (
        <div className="w-full rounded-lg shadow-lg p-3">
          <div className="p-1 lg:p-3 flex items-center gap-2 text-[#102448]">
            <input
              type="checkbox"
              checked={allSelected()}
              onChange={() => {
                dispatch(
                  toggleSelectedAll({
                    allRows: data.data.map((item: { id: string }) => item.id),
                  })
                );
              }}
            />
            <span>Select All</span>
          </div>
          {data?.data?.map(
            (item: {
              id: string;
              sender: string;
              is_seen: boolean;
              message: string;
              createdAt: string;
              type: string;
              notif_info: {
                entity_id: string;
              };
            }) => (
              <CardInbox
                id={item.id}
                key={item.id}
                seen={item.is_seen}
                sender={item.sender}
                message={item.message}
                createdAt={item.createdAt}
                type={item.type}
                entityId={item.notif_info?.entity_id}
              />
            )
          )}
        </div>
      )}
      {data?.data ? (
        <Pagination
          onClickP={() => {
            dispatch(setCurrentPageReminder(currentPageReminder - 1));
          }}
          currentPage={currentPageReminder}
          onClickN={() => {
            dispatch(setCurrentPageReminder(currentPageReminder + 1));
          }}
          npage={data?.last_page}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ListReminder;
