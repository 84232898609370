import { useState } from "react";
import FilterButton from "../common/FilterButton";
import ListActivity from "./ListActivity";
import ActivityFilter from "./FilterActivity";

export default function Activity() {
  const [openSearch, setOpenSearch] = useState(false);
  return (
    <div>
      <div className="flex mt-10 px-2">
        <div className="text-xl font-bold text-black my-3">
          Activity Reports
        </div>
        <div className="flex-1"></div>
        <FilterButton openSearch={openSearch} setOpenSearch={setOpenSearch} />
      </div>
      {openSearch && <ActivityFilter />}

      <ListActivity />
    </div>
  );
}
