import { v4 as uuid } from "uuid";
import { uploadFile } from "../../api/apiProperties";
import { SyntheticEvent, useRef, useState } from "react";
import { formatDate, formatTime } from "../../utils/toLocalDate";
import { getUrl } from "../../utils/fileUrl";

const Upload = ({
  onUpload,
  file,
  name,
  disabled = false,
}: {
  onUpload: (id: string, url: string, filename: string) => void;
  name: string;
  file?: { filename: string; path: string; url?: string; createdAt: string };
  disabled?: boolean;
}) => {
  const [progress, setProgress] = useState(0);
  let url;
  if (file && file.path) {
    url = getUrl(file.path);
  } else if (file && file.url) {
    url = file.url;
  }
  const fileRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    const fileInput: HTMLInputElement | null = document.querySelector(
      `#file-${name}`
    );
    if (!fileInput?.files?.length) return;

    const id = uuid();

    const file = fileInput.files[0];
    try {
      const { status } = await uploadFile({ id, file, setProgress });

      if (status === 201) {
        const blob = new Blob([file]);
        onUpload(id, URL.createObjectURL(blob), file.name);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log("file upload failed", error?.message);
      }
    }
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit} id={`myform-${name}`}>
      <input
        type="file"
        ref={fileRef}
        disabled={disabled}
        className="hidden"
        id={`file-${name}`}
        onChange={() => {
          if (fileRef.current) {
            // console.log("files are", fileRef.current?.files[0].name);
            // setFileName(fileRef.current?.files[0].name);
            // const blob = new Blob([fileRef.current?.files[0]]);
            // setUrl(URL.createObjectURL(blob));
          }
          (
            document.querySelector(`#myform-${name}`) as HTMLFormElement
          )?.requestSubmit();
        }}
      />
      {!file?.filename ? (
        <button
          className="py-1 px-3 rounded-md border bg-prima-light border-prima-light-200 text-dark-blue w-full h-11"
          type="button"
          onClick={() =>
            (document.querySelector(`#file-${name}`) as HTMLElement)?.click()
          }
        >
          + Upload File{" "}
          {progress !== 0 && <span>({(progress * 100).toFixed(2)}%)</span>}
        </button>
      ) : (
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-x-3">
          <div className="border-[#102448]/[.20] border p-2  rounded flex items-center gap-2 w-full ">
            <span className="flex-1">
              {file?.filename.length > 15
                ? `${file?.filename.substring(0, 15)}...`
                : `${file?.filename}`}
            </span>
            <span
              onClick={() => {
                if (formRef.current) {
                  formRef.current.reset();
                }
                setProgress(0);
                onUpload("", "", "");
              }}
              className="cursor-pointer material-symbols-outlined text-[#102448] items-center"
            >
              delete
            </span>
            <a
              className="cursor-pointer material-symbols-outlined text-[#102448] items-center"
              download={file?.filename}
              href={url}
            >
              download
            </a>
          </div>
          <div className="flex lg:block gap-2 lg:gap-0 justify-between text-[#10244840]">
            <div>{formatDate(file.createdAt)}</div>
            <div>{formatTime(file.createdAt)}</div>
          </div>
        </div>
      )}
    </form>
  );
};

export default Upload;
