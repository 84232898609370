import { useSelector } from "react-redux";
import { getUrl } from "../../utils/fileUrl";
import { RootState } from "../../app/store";

const ProfileResponsive = () => {
  const profile = useSelector((state: RootState) => state.login.profile);

  return (
    <div className="flex gap-2 justify-between items-center mr-2 ">
      <div className=" flex flex-1 items-center">
        <div className="w-10 h-10 rounded-full flex items-center justify-center">
          {/* {profile?.profile_picture && ( */}
          {profile?.profile_picture != null ? (
            <img
              className="w-full h-full rounded-full object-cover"
              src={getUrl(profile?.profile_picture?.path)}
              alt=""
            />
          ) : (
            <span className="flex justify-center items-center font-bold w-10 h-10 rounded-full capitalize border text-white bg-[#102448]">
              {profile?.name.substring(0, 1)} {profile?.surname.substring(0, 1)}
            </span>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <p className="capitalize text-[#102448] text-xs cursor-default">
          {profile?.name} {profile?.surname}
        </p>
      </div>
    </div>
  );
};

export default ProfileResponsive;
