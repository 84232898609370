import { createSlice } from "@reduxjs/toolkit";
import { DealType } from "../validation/deal-type";
import { FileObject, FileType } from "../types/fileType";
import { PropertyType } from "../validation/PropertyTypes";

const initialDeal = {
  prima_broker_commission: 0.5,
  prima_broker: "",
  external_agent_id: "",
  external_property_id: "",
  property_comment: "",
  is_company_owner: true,
  owner_id: "",
  owner_company_id: "",
  is_company_buyer: true,
  buyer_company_id: "",
  buyer_id: "",
  is_company_registered_dld: true,
  contract_a_id: "",
  contract_b_id: "",
  contract_f_id: "",
  contract_a_expiry_date: "",
  contract_f_expiry_date: "",
  contract_password: "",
  is_kyc_done: true,
  contract_commission_amount: "",
  is_amount_cash: true,
  is_ready_with_phpp: true,
  is_mortgaged: true,
  deal_attachment_ids: [],
  rent_payments: [],
  search_owner: "",
  search_buyer: "",
  search_company_owner: "",
  search_company_buyer: "",
};
export interface DealState {
  // validationErrors: object;
  validationErrors: Partial<DealType>;
  deal: Partial<DealType> & {
    prima_broker_commission: number;
    deal_id?: string;
    deal_attachments?: FileType[];
    contract_a?: FileObject;
    contract_b?: FileObject;
    contract_f?: FileObject;
    noc?: FileObject;
    new_title_dead?: FileObject;
    kyc_proof?: FileObject;
    remaining_cheques?: FileObject;
    tenancy_contract?: FileObject;
    addmen?: FileObject;
    search_owner?: string;
    search_buyer?: string;
    search_company_owner?: string;
    search_company_buyer?: string;
    property?: Partial<PropertyType> & { id?: string };
  };
}
const initialState: DealState = {
  validationErrors: {},
  deal: initialDeal,
};
export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setBroker: (state, action) => {
      state.deal.prima_broker_commission = action.payload;
      state.deal.prima_broker = String(
        Number(state.deal.prima_company) * state.deal.prima_broker_commission
      );
    },
    setDeal: (state, action) => {
      if (action.payload.prima_company) {
        state.deal.prima_broker = String(
          0.5 * Number(action.payload.prima_company)
        );
      }
      // if (action.payload.prima_company) {
      //   state.deal.prima_broker = String(
      //     Number(action.payload.prima_company) *
      //       state.deal.prima_broker_commission
      //   );
      // }
      if (action.payload.prima_company) {
        state.deal.vat_of_prima_commission = String(
          (0.05 * Number(action.payload.prima_company)).toFixed(2)
        );
      }
      if (action.payload.deal_attachments) {
        state.deal.deal_attachment_ids = action.payload.deal_attachments.map(
          (item: { file_id: string; id: string }) => item.file_id || item.id
        );
      }
      state.deal = { ...state.deal, ...action.payload };
    },
    clearDeal: (state) => {
      state.deal = initialDeal;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    removeFile: (state, action) => {
      state.deal.deal_attachment_ids = state.deal?.deal_attachment_ids?.filter(
        (id) => id !== action.payload
      );
      state.deal.deal_attachments = state.deal.deal_attachments?.filter(
        (photo: FileType) => photo.id !== action.payload
      );
    },
  },
});
export const {
  setBroker,
  setDeal,
  clearDeal,
  setValidationErrors,
  resetValidationErrors,
  removeFile,
} = dealSlice.actions;
export default dealSlice.reducer;
