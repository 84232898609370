import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
const SecondRange = () => {
  const { start_date, end_date } = useSelector(
    (state: RootState) => state.rentandsalecard.filter
  );
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  console.log("First day=" + firstDay);
  console.log("Last day = " + lastDay);

  return start_date && end_date
    ? `${start_date} - ${end_date}`
    : `${firstDay.getFullYear()}-${firstDay.getMonth()}-${firstDay.getDay()} -
 ${lastDay.getFullYear()}-${lastDay.getMonth()}-${lastDay.getDate()}
`;
};

export default SecondRange;
