import { useQueryClient } from "@tanstack/react-query";
import { updateNotification } from "../../api/notification";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { clearSelectedRows } from "../../store/notification";

const ReadNotif = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const selectedRows = useSelector(
    (state: RootState) => state.notification.selectedRows
  );
  return (
    <button
      className={`px-7 py-1 flex justify-center border rounded`}
      onClick={async () => {
        await updateNotification(selectedRows);
        await queryClient.invalidateQueries({
          queryKey: ["notification"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["unread"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["assign"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["reminder"],
        });
        await dispatch(clearSelectedRows());
      }}
    >
      Read
    </button>
  );
};

export default ReadNotif;
