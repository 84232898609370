import { createSlice } from "@reduxjs/toolkit";
import { AttachmentType } from "../validation/attachment-type";

const initialAttachment = {
  attachment_file_ids: [],
  propertyId: "",
  search: "",
};
export interface AttachmentState {
  filter: {
    search: string;
  };
  validationErrors: object;
  attachment: AttachmentType;
}
const initialFilter = {
  search: "",
};
const initialState: AttachmentState = {
  filter: initialFilter,
  validationErrors: {},
  attachment: initialAttachment,
};
export const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setAttachment: (state, action) => {
      state.attachment = { ...state.attachment, ...action.payload };
      if (action.payload.deal_attachments) {
        state.attachment.attachment_file_ids =
          action.payload.attachment_file_ids.map(
            (item: { file_id: string; id: string }) => item.file_id || item.id
          );
      }
    },
    clearAttachment: (state) => {
      state.attachment = initialAttachment;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
  },
});
export const {
  setSearch,
  setAttachment,
  clearAttachment,
  setValidationErrors,
  resetValidationErrors,
  resetFilter,
} = attachmentSlice.actions;
export default attachmentSlice.reducer;
