import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import {
  setComperEndDate,
  setComperStartDate,
} from "../../../../store/rent-sale-card";

const CompareFilter = () => {
  const dispatch = useDispatch();
  const startDate = useSelector(
    (state: RootState) => state.rentandsalecard.filter.compare_start_date
  );
  const endDate = useSelector(
    (state: RootState) => state.rentandsalecard.filter.compare_end_date
  );
  return (
    <div className="">
      <label className="text-md block mb-2 font-Outfit text-[#102448]/[.70]">
        Compare To
      </label>
      <Datepicker
        primaryColor="blue"
        value={{ startDate, endDate }}
        onChange={(value: DateValueType) => {
          if (value?.startDate) {
            dispatch(setComperStartDate(value.startDate));
          }
          if (value?.endDate) {
            dispatch(setComperEndDate(value.endDate));
          }
        }}
        showShortcuts={true}
      />
    </div>
  );
};

export default CompareFilter;
