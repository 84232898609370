// import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { ComponentPropsWithoutRef } from "react";
type MyTabsProps = {
  text: string;
  validation?: string;
} & ComponentPropsWithoutRef<"div">;
export default function MyTabs({ text, validation, ...props }: MyTabsProps) {
  return (
    <Tab
      className={`outline-none p-2 font-bold capitalize shrink-0  ${
        validation ? "text-red-500" : "text-[#102448]/[.70]"
      }`}
    >
      {({ selected }) => (
        <div
          {...props}
          className={
            selected
              ? "text-[#102448] border-b-2 border-b-[#102448] py-2 capitalize"
              : validation
              ? "text-[#FF0000]  py-2 capitalize"
              : "text-[#102448]/[0.6] py-2 capitalize"
          }
        >
          {text}
        </div>
      )}
    </Tab>
  );
}
