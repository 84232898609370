import { useState } from "react";
import { formatDate, formatTime } from "../../utils/toLocalDate";
import ConfirmMessage from "../common/ConfirmMessage";
import { removeNotification } from "../../api/notification";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorMessage, showSuccessMessage } from "../../helpers/alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { toggleSelectedRows } from "../../store/notification";
import SeeMore from "./SeeMore";
type CardInboxProps = {
  seen: boolean;
  message: string;
  createdAt: string;
  id: string;
  sender: string;
  type: string;
  entityId: string;
};
const CardInbox = ({
  seen,
  message,
  createdAt,
  id,
  sender,
  type,
  entityId,
}: CardInboxProps) => {
  const dispatch = useDispatch();
  const selectedRows = useSelector(
    (state: RootState) => state.notification.selectedRows
  );
  const queryClient = useQueryClient();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const deleteEnquiryMutation = useMutation({
    mutationFn: removeNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notification"] });
      queryClient.invalidateQueries({ queryKey: ["unread"] });
      queryClient.invalidateQueries({ queryKey: ["assign"] });
      queryClient.invalidateQueries({ queryKey: ["reminder"] });
      showSuccessMessage(`The message has been deleted successfully`);
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ["notification"] });
      queryClient.invalidateQueries({ queryKey: ["unread"] });
      queryClient.invalidateQueries({ queryKey: ["assign"] });
      queryClient.invalidateQueries({ queryKey: ["reminder"] });
      showErrorMessage(`Deleting message was not successfull`);
    },
  });
  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };
  const handleConfirmDelete = (id?: string) => {
    deleteEnquiryMutation.mutate(id);
    setShowDeleteConfirmation(false);
  };
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <div
        className={`hidden lg:flex rounded  justify-between p-3 items-center gap-5 mb-1  ${
          seen ? "text-[#102448]/[0.60]" : " text-[#102448] font-bold"
        }`}
      >
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={selectedRows.includes(id)}
            onChange={() => {
              dispatch(toggleSelectedRows(id));
            }}
          />
          <span className={`w-80 flex items-center gap-0.5 cursor-default`}>
            {!seen ? (
              <span className="flex w-2 h-2 rounded-full bg-blue-400"></span>
            ) : (
              <span className="flex w-2 h-2 rounded-full bg-transparent"></span>
            )}
            {sender}
          </span>
        </div>

        <div className="flex-1 flex justify-center cursor-default">
          <span className="w-full flex justify-start items-center">
            {message}
          </span>
        </div>
        <span className="cursor-default">
          {formatDate(createdAt)} {formatTime(createdAt)}
        </span>
        <span
          title="delete"
          className="material-symbols-outlined cursor-pointer"
          onClick={() => handleDelete()}
        >
          delete
        </span>
        <SeeMore entityId={entityId} type={type} seen={seen} />
      </div>
      <div
        className={`flex flex-col gap-2 lg:hidden ${
          seen ? "text-[#102448]/[0.60]" : " text-[#102448] font-bold"
        }`}
      >
        <div className="flex items-center justify-between pl-1">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={selectedRows.includes(id)}
              onChange={() => {
                dispatch(toggleSelectedRows(id));
              }}
            />
            {!seen ? (
              <span className="flex w-2 h-2 rounded-full bg-blue-400"></span>
            ) : (
              <span className="flex w-2 h-2 rounded-full bg-transparent"></span>
            )}
            <span
              className={`cursor-default font-bold ${
                seen ? "text-[#102448]/[0.60]" : " text-[#102448] font-bold"
              }`}
            >
              {sender}
            </span>
          </div>
          <div className="flex flex-col text-xs">
            <p className="text-[#102448]/[0.60] flex items-center">
              {formatDate(createdAt)}
            </p>
            <p className="text-[#102448]/[0.60] flex items-center">
              {formatTime(createdAt)}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap pl-1">{message}</div>
        <div className="p-1  my-2 bg-[#102448]/[0.05] flex items-center justify-evenly lg:hidden rounded-full">
          <span
            className="material-symbols-outlined text-[#102448]/[0.60]"
            onClick={() => handleDelete()}
          >
            delete
          </span>
          <SeeMore seen={seen} entityId={entityId} type={type} />
        </div>
      </div>
      {showDeleteConfirmation && (
        <ConfirmMessage
          id={id}
          message="Are you sure you want to delete this notification?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
    </>
  );
};

export default CardInbox;
