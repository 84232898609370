import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import SideLink from "./components/common/SideLink";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./store/loginSlice";
import { hasPermission } from "./utils/hasPermission";
import { RootState } from "./app/store";
import Profile from "./components/common/Profile";
import { useQueryClient } from "@tanstack/react-query";
import ProfileResponsive from "./components/common/ProfileResponsive";
import ListCounterUnRead from "./components/inbox/ListCountUnread";
import useClickOutSide from "./hooks/useClickOutSide";

const Layout = () => {
  const boxRef = useRef<HTMLDivElement>(null);
  useClickOutSide(boxRef, () => {});
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const sideBar = useRef<HTMLDivElement>(null);
  const location = useLocation();
  useEffect(() => {
    const title = location.pathname.split("/")[1];
    const postitle = location.pathname.split("/")[2];
    console.log("TITLEEE", postitle);

    if (title) {
      document.title = `${title[0].toUpperCase() + title.slice(1)}`;
      if (postitle && postitle.length <= 17 && postitle.length > 3) {
        document.title = `${postitle[0]?.toUpperCase() + postitle?.slice(1)}`;
      }
    } else {
      document.title = `Prima CRM`;
    }
  }, [location]);
  useEffect(() => {
    return () => {
      document.title = "Prima CRM";
    };
  }, []);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const token = useSelector((state: RootState) => state.login.token);
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );
  const dispatch = useDispatch();

  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="w-full h-screen flex flex-col">
      <div className="flex flex-1">
        <div
          ref={sideBar}
          className="hidden lg:flex w-56 flex-col bg-[#102448] pt-7"
        >
          <div className="w-full grow-0">
            <img className="mx-auto" src="/logo_mobile.png" alt="" />
          </div>
          <div className="w-full items-start justify-end flex flex-1">
            <ul className="flex flex-wrap justify-end content-center mt-20">
              <SideLink
                onClick={() => {
                  setHamburgerMenu(false);
                }}
                href="/inbox"
                text="Inbox"
                icon="inbox"
                isActive={location.pathname.startsWith("/inbox")}
              />
              {hasPermission(permissions, "Read", "reports") && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href="/dashboard"
                  text="Dashboard"
                  icon="dashboard"
                  isActive={location.pathname.startsWith("/dashboard")}
                />
              )}
              {hasPermission(permissions, "Read", "property") && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href="/properties"
                  text="Properties"
                  icon="topic"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/properties")}
                />
              )}
              {hasPermission(permissions, "Read", "enquiry") && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href="/enquiries"
                  text="Enquiries"
                  icon="pageview"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/enquiries")}
                />
              )}
              {hasPermission(permissions, "Read", "customer") && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href="/customers"
                  text="Customers"
                  icon="Person"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/customers")}
                />
              )}
              {hasPermission(permissions, "Read", "lead") && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href="/leads"
                  text="Leads"
                  icon="groups"
                  isActive={location.pathname.startsWith("/leads")}
                />
              )}
              {(hasPermission(permissions, "Add", "user") ||
                hasPermission(permissions, "Read", "role")) && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href={
                    hasPermission(permissions, "Add", "user")
                      ? "/settings/users"
                      : "/settings"
                  }
                  text="Settings"
                  icon="settings"
                  isActive={location.pathname.startsWith("/settings/users")}
                />
              )}

              {hasPermission(permissions, "Read", "user activity") && (
                <SideLink
                  onClick={() => {
                    setHamburgerMenu(false);
                  }}
                  href="/reports"
                  text="Reports"
                  icon="browse_activity"
                  toggleIcon="add"
                  isActive={location.pathname.startsWith("/reports")}
                />
              )}
            </ul>
          </div>
        </div>

        <div className="px-1 lg:px-7 pt-3 flex-1 ">
          <div className="w-full flex items-center p-3 lg:px-3 lg:py-1 rounded shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)] relative ">
            <div
              className="w-12  cursor-pointer flex justify-center"
              onClick={() => {
                if (sideBar.current) {
                  if (window.innerWidth > 992) {
                    sideBar.current.classList.toggle("lg:flex");
                  } else {
                    sideBar.current.classList.remove("lg:flex");
                    sideBar.current.classList.toggle("hidden");
                  }
                }
              }}
            >
              <span className="material-symbols-outlined text-[#102448] items-center">
                sort
              </span>
            </div>
            <div className="grow-[3] flex items-center pl-4">
              <div className="flex-1 "></div>
              <div className="flex items-center">
                {hamburgerMenu ? (
                  <span className="h-12"></span>
                ) : (
                  <div className="">
                    <Profile />
                  </div>
                )}
                <span className="relative items-center">
                  <span className="flex items-center">
                    <span
                      onClick={() => {
                        navigate("/inbox");
                      }}
                      className="material-symbols-outlined cursor-pointer   text-[#102448]"
                    >
                      notifications
                    </span>
                  </span>
                  <ListCounterUnRead />
                </span>
                <span
                  className={`flex items-center text-[#102448] px-2 hover:bg-[#102448]/[0.20] rounded cursor-pointer ${
                    hamburgerMenu ? "bg-[#102448]/[0.20]" : "bg-transparent"
                  }`}
                  onClick={() => {
                    setHamburgerMenu(!hamburgerMenu);
                  }}
                >
                  <span className="material-symbols-outlined ">for_you</span>
                </span>
              </div>
              <div
                ref={boxRef}
                className={`border border-red-500 absolute right-0 top-[90%] w-fit z-20 flex flex-col gap-2 rounded p-3 bg-white shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)]    transition-all ${
                  hamburgerMenu ? `flex` : `hidden`
                }`}
              >
                <div className="flex gap-1 justify-center items-center ">
                  <ProfileResponsive />
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={async () => {
                      localStorage.removeItem("token");
                      await queryClient.removeQueries();
                      dispatch(setToken(null));
                    }}
                    className="bg-[#102448] text-sm text-white rounded-md p-2"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Suspense fallback={<div></div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
