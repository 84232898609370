import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "../validation/UserType";
import { FileObject } from "../types/fileType";

const initialUser = {
  currentPage: 1,
  name: "",
  surname: "",
  agent_name: "",
  agent_surName: "",
  email: "",
  phone_number: "",
  phone_dial_id: 784,
  phone: "",
};
export interface UserState {
  currentPage: number;
  // sort: {
  //   field: string;
  //   order: string;
  // };
  filter: {
    search: string;
    userRole?: { title: string; id: string };
  };
  validationErrors: Partial<UserType>;
  user: UserType & { BRN_file?: FileObject };
}
const initialFilter = {
  search: "",
};
const initialState: UserState = {
  currentPage: 1,
  // sort: {
  //   field: "",
  //   order: "",
  // },
  filter: initialFilter,
  validationErrors: {},
  user: initialUser,
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // setSort: (state, action) => {
    //   const { field, order } = action.payload;
    //   state.sort = { field, order };
    // },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setRoleFilter: (state, action) => {
      state.filter.userRole = action.payload;
      state.currentPage = 1;
    },
    setUser: (state, action) => {
      if (action.payload.phone_number) {
        state.user.phone = action.payload.phone_number;
      }
      state.user = { ...state.user, ...action.payload };
    },
    clearUser: (state) => {
      state.user = initialUser;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
});
export const {
  setUser,
  clearUser,
  setValidationErrors,
  resetValidationErrors,
  // setSort,
  setSearch,
  setRoleFilter,
  resetFilter,
  setCurrentPage,
} = userSlice.actions;
export default userSlice.reducer;
