export function formatDate(date: string) {
  if (!date) {
    return;
  }
  let current: Date | string = date;

  if (typeof current === "string") {
    current = new Date(date);
  }

  const yyyy = current.getFullYear();
  let mm: string | number = current.getMonth() + 1; // Months start at 0!
  let dd: string | number = current.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const result = dd + "/" + mm + "/" + yyyy;
  return result;
}

export function formatTime(date: string) {
  if (!date) {
    return;
  }
  let current: Date | string = date;

  if (typeof current === "string") {
    current = new Date(date);
  }
  return current.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}
