import { createSlice } from "@reduxjs/toolkit";
import { CustomerType } from "../validation/CustomerType";
import { FileObject } from "../types/fileType";

const initialCustomer = {
  currentPage: 1,
  customer_name: "",
  customer_surName: "",
  phone_number: "",
  email: "",
  passport_number: "",
  nationality: null,
  birthplace: "",
  uae_resident: false,
  phone_dial_id: 784,
  alternate_phone_number: "",
};
export interface CustomerState {
  currentPage: number;
  filter: {
    search: string;
    start_date: null;
    end_date: null;
  };
  validationErrors: Partial<CustomerType>;
  customer: Partial<CustomerType> & {
    emirates_id_proof?: FileObject;
    passport_proof?: FileObject;
    vat_certificate?: FileObject;
    visa_proof?: FileObject;
  };
}
const initialFilter = {
  search: "",
  start_date: null,
  end_date: null,
};
const initialState: CustomerState = {
  currentPage: 1,
  filter: initialFilter,
  validationErrors: {},
  customer: initialCustomer,
};
export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setStartDate: (state, action) => {
      state.filter.start_date = action.payload;
      state.currentPage = 1;
    },
    setEndDate: (state, action) => {
      state.filter.end_date = action.payload;
      state.currentPage = 1;
    },
    setCustomer: (state, action) => {
      const newState = { ...state.customer, ...action.payload };

      if (newState.is_same_as_current_address) {
        newState.permanent_country = newState.country_of_residence;
        newState.permanent_po_box = newState.po_box;
        newState.permanent_city = newState.city;
        newState.permanent_address = newState.address;
      } else {
        newState.permanent_country = null;
        newState.permanent_po_box = null;
        newState.permanent_city = null;
        newState.permanent_address = null;
      }
      state.customer = newState;
    },
    clearCustomer: (state) => {
      state.customer = initialCustomer;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
  },
});
export const {
  setCustomer,
  setValidationErrors,
  clearCustomer,
  resetValidationErrors,
  // setSort,
  setSearch,
  setEndDate,
  setStartDate,
  resetFilter,
  setCurrentPage,
} = customerSlice.actions;
export default customerSlice.reducer;
