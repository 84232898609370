import { useQuery } from "@tanstack/react-query";
import Pagination from "../common/Pagination";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getUserActivity } from "../../api/user-activity";
import CardActivity from "./CardActivity";

const ListActivity = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { search, start_date, end_date } = useSelector(
    (state: RootState) => state.useractivity.filter
  );

  const queries = [];
  if (search) queries.push(`search=${search}`);
  if (start_date) queries.push(`start_date=${start_date}`);
  if (end_date) queries.push(`end_date=${end_date}`);

  queries.push("take=10");
  queries.push(`page=${currentPage}`);
  const params = queries.length ? "?" + queries.join("&") : "";

  const { data } = useQuery({
    queryKey: ["activity", queries],
    queryFn: async function () {
      const result = await getUserActivity(params);
      return result;
    },
  });

  return (
    <>
      <div className="w-full hidden lg:flex items-center mt-7 ">
        <div className="w-1/12 "></div>
        <div className="flex flex-1 items-center justify-between  ">
          <div className="w-1/4 text-lg  p-3 flex items-center text-[#102448] font-bold">
            Name
          </div>
          <div className="w-1/4 text-lg  p-3 flex items-center text-[#102448] font-bold">
            Role
          </div>
          <div className="w-1/4 text-lg  p-3 flex items-center text-[#102448] font-bold">
            Date
          </div>
        </div>
      </div>
      {data?.data?.length == 0 ? (
        <div className="w-full h-[50vh] flex flex-col gap-2 justify-center items-center">
          <img src="/noRecord.png" alt="" />
          <span className="text-[#102448]/[0.60]">No records yet</span>
        </div>
      ) : (
        data?.data?.map(
          (item: {
            user_id: string;
            user_name: string;
            user_surname: string;
            roles: [];
            profile_picture: string;
            createdat: string;
          }) => <CardActivity item={item} />
        )
      )}

      {data ? (
        <Pagination
          onClickP={() => {
            setCurrentPage((page) => page - 1);
          }}
          currentPage={currentPage}
          onClickN={() => {
            setCurrentPage((page) => page + 1);
          }}
          npage={data?.last_page}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ListActivity;
