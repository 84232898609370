import { createSlice } from "@reduxjs/toolkit";
import { ExternalAgentType } from "../validation/external-agent";
import { FileObject } from "../types/fileType";

const initialExternalAgent = {
  is_external_agent: true,
  company_name: "",
  rera_id: "",
  external_agent_name: null,
};
export interface ExternalAgentState {
  validationErrors: object;
  externalagent: ExternalAgentType & { rera?: FileObject };
}
const initialState: ExternalAgentState = {
  validationErrors: {},
  externalagent: initialExternalAgent,
};
export const externalagentSlice = createSlice({
  name: "externalagent",
  initialState,
  reducers: {
    setExternalAgent: (state, action) => {
      state.externalagent = { ...state.externalagent, ...action.payload };
    },
    clearExternalAgent: (state) => {
      state.externalagent = initialExternalAgent;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setExternalAgent,
  clearExternalAgent,
  setValidationErrors,
  resetValidationErrors,
} = externalagentSlice.actions;
export default externalagentSlice.reducer;
