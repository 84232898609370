import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
const FirstRange = () => {
  const { start_date, end_date } = useSelector(
    (state: RootState) => state.rentandsalecard.filter
  );
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  console.log("First day=" + firstDay);
  console.log("Last day = " + lastDay);

  return start_date && end_date
    ? `${start_date} - ${end_date}`
    : `${firstDay.getFullYear()}-${
        firstDay.getMonth() + 1
      }-${firstDay.getDay()} -
 ${lastDay.getFullYear()}-${lastDay.getMonth() + 1}-${lastDay.getDate()}
`;
};

export default FirstRange;
