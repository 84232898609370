import { useDispatch, useSelector } from "react-redux";
import { removeAllNotification } from "../../api/notification";
import { RootState } from "../../app/store";
import {
  setCurrentPage,
  setCurrentPageAssigne,
  setCurrentPageReminder,
  setCurrentPageUnread,
} from "../../store/notification";
import { useQueryClient } from "@tanstack/react-query";

const ClearNotif = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const selectedRows = useSelector(
    (state: RootState) => state.notification.selectedRows
  );
  const currentPage = useSelector(
    (state: RootState) => state.notification.currentPage
  );
  const currentPageUnread = useSelector(
    (state: RootState) => state.notification.currentPageUnread
  );
  const currentPageReminder = useSelector(
    (state: RootState) => state.notification.currentPageReminder
  );
  const currentPageAssigne = useSelector(
    (state: RootState) => state.notification.currentPageAssigne
  );

  return (
    <button
      className="px-5 py-1 flex justify-center border rounded"
      onClick={async () => {
        await removeAllNotification(selectedRows);
        await queryClient.invalidateQueries({
          queryKey: ["notification"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["unread"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["assign"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["reminder"],
        });
        if (currentPage > 1) dispatch(setCurrentPage(currentPage - 1));
        if (currentPageUnread > 1)
          dispatch(setCurrentPageUnread(currentPageUnread - 1));
        if (currentPageReminder > 1)
          dispatch(setCurrentPageReminder(currentPageReminder - 1));
        if (currentPageAssigne > 1)
          dispatch(setCurrentPageAssigne(currentPageAssigne - 1));
      }}
    >
      Clear
    </button>
  );
};

export default ClearNotif;
