import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../../common/Upload";
import { setDeal } from "../../../../../store/deal-slice";
import { RootState } from "../../../../../app/store";

const NOCUpload = () => {
  const dispatch = useDispatch();
  const noc = useSelector((state: RootState) => state.deal.deal.noc);
  return (
    <div className="flex flex-col justify-between">
      <label className={"justify-self-end text-[#102448]/[.70] mb-1"}>
        NOC
      </label>
      <div className="w-full">
        <Upload
          name="noc"
          file={noc}
          onUpload={(noc_id: string, url: string, filename: string) =>
            dispatch(
              setDeal({
                noc_id,
                noc: {
                  url: url,
                  filename: filename,
                  createdAt: new Date(),
                },
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default NOCUpload;
