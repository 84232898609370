import { Tab } from "@headlessui/react";
import Tabs from "../common/Tabs";
import ListInbox from "./ListInbox";
import ListUnRead from "./ListUnRead";
import ListReminder from "./ListReminder";
import ListAssign from "./ListAssigne";
import ClearNotif from "./ClearNotif";
import ReadNotif from "./ReadNotif";

const Inbox = () => {
  return (
    <div className="w-full flex flex-col px-2 gap-2 pt-7">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-bold text-[#102448] my-3">Inbox</h1>
        <div className="flex gap-3">
          <ReadNotif />
          <ClearNotif />
        </div>
      </div>
      <div className="">
        <Tab.Group>
          <Tab.List className="flex shrink-0 overflow-x-auto">
            <Tabs text="All" />
            <Tabs text="Unread Only" />
            <Tabs text="Reminder" />
            <Tabs text="Assigned To Me" />
            <Tabs text="Mentioning Me" />
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <ListInbox />
            </Tab.Panel>
            <Tab.Panel>
              <ListUnRead />
            </Tab.Panel>
            <Tab.Panel>
              <ListReminder />
            </Tab.Panel>
            <Tab.Panel>
              <ListAssign />
            </Tab.Panel>
            <Tab.Panel>4</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Inbox;
