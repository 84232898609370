import { setLogin } from "../../store/loginSlice";
import Text from "../common/TextField";
import LoginButton from "./LoginButton";

export default function Login() {
  const TextField = (props: any) => {
    return <Text slice="login" object="login" action={setLogin} {...props} />;
  };
  return (
    <div className="w-full h-[100vh] flex flex-col items-center justify-center bg-[#102448]">
      <form
        action=""
        className="rounded-lg p-10 flex flex-col justify-center gap-8 border bg-white"
      >
        <h2 className="flex flex-col items-center  justify-center text-xl font-bold">
          <img className="mx-auto" src="/logo_mobile.png" alt="" />
          <div className="mt-2">Prima Luxury CRM</div>
        </h2>
        <TextField field="email" type="text" label="Email*" name="email" />
        <TextField
          field="password"
          type="password"
          label="Password*"
          name="password"
        />
        <LoginButton />
      </form>
    </div>
  );
}
