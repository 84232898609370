type PaginationProps = {
  onClickP: () => void;
  onClickN: () => void;
  currentPage: number;
  npage: number;
};

const Pagination = ({
  onClickP,
  onClickN,
  currentPage,
  npage,
}: PaginationProps) => {
  return (
    <div className="w-[100%] flex justify-between lg:justify-end">
      {npage > 1 ? (
        <>
          <div className="flex justify-center items-center">
            <button
              onClick={onClickP}
              disabled={currentPage === 1}
              className={`p-2 bg-[#102448]/[0.02] border border-[#102448]/[0.20] rounded text-[#102448] flex items-center justify-center ${
                currentPage === 1 ? "text-[#102448]/[0.20]" : ""
              }`}
            >
              <span className="material-symbols-outlined">
                keyboard_backspace
              </span>
            </button>
            <button
              onClick={onClickN}
              disabled={currentPage === npage}
              className={`w-fit bg-[#102448]  capitalize rounded outline-none p-2 mx-1 flex justify-center items-center ${
                currentPage === npage
                  ? "bg-white text-[#102448]/[0.20] border border-[#102448]/[0.20] "
                  : "text-white"
              }`}
            >
              next page
              <span className="ml-1 material-symbols-outlined">
                trending_flat
              </span>
            </button>
          </div>
          <div className="w-1/3 p-5 flex justify-center items-center text-[#102448] font-bold ">
            Page
            <span className="w-fit px-2 py-1 mx-2 rounded border border-[#102448]/[0.20] bg-[#102448]/[0.02] text-[#102448]">
              {currentPage}
            </span>
            of
            <span className="w-fit px-2 py-1 mx-2 rounded border border-[#102448]/[0.20] bg-[#102448]/[0.02] text-[#102448]">
              {npage}
            </span>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
