import { getUrl } from "../../utils/fileUrl";
import { formatDate, formatTime } from "../../utils/toLocalDate";

type CardActivityProps = {
  item: {
    user_id: string;
    user_name: string;
    user_surname: string;
    roles: [];
    profile_picture: string;
    createdat: string;
  };
};

const CardActivity = ({ item }: CardActivityProps) => {
  return (
    <>
      <div
        className="w-full hidden lg:flex items-center py-3 border-b"
        key={item.user_id}
      >
        <div className="w-1/12 flex items-center  ">
          {item.profile_picture ? (
            <img
              className="w-16 h-16 rounded-full object-cover"
              src={getUrl(item.profile_picture)}
            />
          ) : (
            <span className="rounded-full bg-dark-blue w-16 h-16"></span>
          )}
        </div>
        <div className="flex flex-1 items-center justify-between  ">
          <div className="w-1/4   p-3 flex text-[#102448]/[0.60] items-center capitalize">
            {item.user_name} {item.user_surname}
          </div>
          <div className="w-1/4   p-3 flex text-[#102448]/[0.60] items-center capitalize">
            {item.roles?.map((role) => {
              return <span className="mr-1">{role}</span>;
            })}
          </div>
          <div className="w-1/4   p-3 flex flex-col text-[#102448]/[0.60] justify-center">
            <div>{formatDate(item.createdat)}</div>
            <div>{formatTime(item.createdat)}</div>
          </div>
        </div>
      </div>
      <div className="w-full px-2 flex flex-col border-b py-2 lg:hidden">
        <div className="flex items-center justify-between">
          <div className="flex gap-1">
            {item.profile_picture ? (
              <img
                className="w-10 h-10 rounded-full object-cover"
                src={getUrl(item.profile_picture)}
              />
            ) : (
              <span className="rounded-full bg-dark-blue w-10 h-10"></span>
            )}
            <div className="flex text-[#102448]/[0.60] items-center capitalize">
              {item.user_name} {item.user_surname}
            </div>
          </div>
          <div className="flex text-[#102448]/[0.60] items-center capitalize">
            {item.roles?.map((role) => {
              return <span className="text-xs">{role}</span>;
            })}
          </div>
        </div>
        <div className="flex gap-1  text-[#102448]/[0.60]">
          <span className="w-10"></span>
          {formatDate(item.createdat)} {formatTime(item.createdat)}
        </div>
      </div>
    </>
  );
};

export default CardActivity;
