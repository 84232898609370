import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getTotalProperty } from "../../../api/dashboard/reports";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

const options = {
  chart: {
    type: "column",
  },
  title: {
    text: "Total Number of Listing Property",
  },
  series: [],
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    allowDecimals: false,
    title: { text: "Number of Listings" },
  },
  plotOptions: {
    column: {
      stacking: "normal",
    },
  },
};

function TotalListing() {
  const listingYear = useSelector(
    (state: RootState) => state.chartproperty.filter.listingYear
  );

  const queries = [];
  if (listingYear) queries.push(`year=${new Date(listingYear).getFullYear()}`);
  const params = queries.length ? "?" + queries.join("&") : "";
  console.log("queries", queries);

  const { data, isLoading } = useQuery({
    queryKey: ["totallistings", listingYear],
    queryFn: async () => await getTotalProperty(params),
  });
  if (isLoading) {
    console.log("inside isloading");
    return "";
  }
  console.log("data is", data);

  options.series = data?.series;
  options.xAxis.categories = data?.categories;
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default TotalListing;
