import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getTotalEnquiry } from "../../../api/dashboard/reports";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

const options = {
  chart: {
    type: "column",
  },
  title: {
    text: "Total Number of Enquiry",
  },
  series: [],
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    allowDecimals: false,
    title: { text: "Number of Enquiry" },
  },
  plotOptions: {
    column: {
      stacking: "normal",
    },
  },
};

function TotalEnquiry() {
  const enquiryYear = useSelector(
    (state: RootState) => state.chartproperty.filter.enquiryYear
  );

  const queries = [];
  if (enquiryYear) queries.push(`year=${new Date(enquiryYear).getFullYear()}`);
  const params = queries.length ? "?" + queries.join("&") : "";
  console.log("queries", queries);
  const { data, isLoading } = useQuery({
    queryKey: ["TotalEnquiry", enquiryYear],
    queryFn: async () => await getTotalEnquiry(params),
  });
  if (isLoading) {
    return "";
  }

  options.series = data?.series;
  options.xAxis.categories = data?.categories;
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default TotalEnquiry;
