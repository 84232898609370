type downloadFilePropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  filePath: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type?: any;
};
export const downloadFileHelper = ({
  data,
  filePath,
  type,
}: downloadFilePropsType) => {
  const blob = new Blob([data], {
    type: type,
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filePath);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
