import { useDispatch, useSelector } from "react-redux";
import { clearDeal, setValidationErrors } from "../../../../store/deal-slice";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../helpers/alert";
import { RootState } from "../../../../app/store";
import { DealSchema } from "../../../../validation/deal-type";

const SaveDeal = () => {
  const { id } = useParams();
  const deal = useSelector((state: RootState) => state.deal.deal);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function handleSave() {
    const parsed = DealSchema.safeParse(deal);
    if (!parsed.success) {
      const issue = parsed.error.issues[0];
      showErrorMessage(`${issue.message}`);

      const formattedErrors = parsed.error.issues.reduce(
        (acc, issue) => ({ ...acc, [issue.path[0]]: issue.message }),
        {}
      );
      dispatch(setValidationErrors(formattedErrors));
      return;
    }

    try {
      if (deal.deal_id) {
        await axios.put(`deal/${deal.deal_id}`, parsed.data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log("inside save deal response is", respons);
      }
      showSuccessMessage(`The Deal has been edited successfully`);
      queryClient.invalidateQueries({ queryKey: ["leads", id] });
      dispatch(clearDeal());
      navigate("/leads");
    } catch (error) {
      console.log("something went wrong ", error);
    }
  }
  function handleBack() {
    navigate("/leads");
  }

  return (
    <div className="p-3 w-full lg:w-[calc(100%-224px)] z-10 border-t bg-white fixed bottom-0 border-slate-200 flex items-center right-0 justify-end lg:pr-12">
      <button
        onClick={handleBack}
        className="ml-2 py-1 px-7  text-slate-700 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={handleSave}
        className="w-24 h-[40px] flex justify-center items-center rounded-lg  border border-[#102448]/[0.20] text-md bg-[#102448] text-white"
      >
        Save
      </button>{" "}
    </div>
  );
};

export default SaveDeal;
