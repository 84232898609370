import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getTotalCompanyCustomer } from "../../../api/dashboard/reports";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

const options = {
  chart: {
    type: "column",
  },
  title: {
    text: "Total Number of Company, Customer",
  },
  series: [],
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    allowDecimals: false,
    title: { text: "Number of Company and Customer" },
  },
};

function TotalCustomerCompany() {
  const customerCompanyYear = useSelector(
    (state: RootState) => state.chartproperty.filter.customerCompanyYear
  );

  const queries = [];
  if (customerCompanyYear)
    queries.push(`year=${new Date(customerCompanyYear).getFullYear()}`);
  const params = queries.length ? "?" + queries.join("&") : "";
  console.log("queries", queries);
  const { data, isLoading } = useQuery({
    queryKey: ["TotalCustomerCompanys", customerCompanyYear],
    queryFn: async () => await getTotalCompanyCustomer(params),
  });
  if (isLoading) {
    return "";
  }

  // options.series = data?.series.map((item) => ({
  //   data: item.data.map((item) => Number(item)),
  //   name: item.name,
  // }));
  options.series = data?.series;
  options.xAxis.categories = data?.categories;
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default TotalCustomerCompany;
