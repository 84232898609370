import { z } from "zod";

export const DealSchema = z
  .object({
    external_agent_id: z.string().optional().nullable(),
    external_property_id: z.string().optional().nullable(),
    // property
    property_comment: z.string().optional().nullable(),
    // Owner
    is_company_owner: z.boolean().optional(),
    owner_id: z.string().optional().nullable(),
    owner_company_id: z.string().optional().nullable(),

    //Buyer
    is_company_buyer: z.boolean().optional(),
    buyer_company_id: z.string().optional().nullable(),
    buyer_id: z.string().optional().nullable(),
    is_company_registered_dld: z.boolean().optional().nullable(),

    //Rent Contract
    lead_request_for: z.enum(["to_rent", "to_buy"]).optional().nullable(),
    agent_represent_for_rent: z
      .enum(["tenant", "renter", "both"])
      .optional()
      .nullable(),
    tenancy_contract_id: z.string().optional().nullable(),
    addmen_id: z.string().optional().nullable(),
    start_date_tenancy_contract: z.string().optional().nullable(),
    expiry_date_tenancy_contract: z.string().optional().nullable(),
    rent_commission_amount: z.string().optional().nullable(),
    rent_annual_amount: z.string().optional().nullable(),
    rent_security_deposite_amount: z.string().optional().nullable(),
    rent_payment_list: z.array(
      z.object({
        file_id: z.string().optional(),
        text: z.string().optional(),
      })
    ),

    // Contract

    agent_represent: z.enum(["buyer", "seller", "both"]).optional().nullable(),
    contract_a_number: z.string().optional().nullable(),
    contract_a_expiry_date: z.string().optional().nullable(),
    contract_b_number: z.string().optional().nullable(),
    contract_f_id: z.string().optional().nullable(),
    contract_f_number: z.string().optional().nullable(),
    contract_f_expiry_date: z.string().optional().nullable(),
    contract_password: z.string().optional().nullable(),
    contract_a_id: z.string().optional().nullable(),
    contract_b_id: z.string().optional().nullable(),
    contract_b_expiry_date: z.string().optional().nullable(),
    noc_id: z.string().optional().nullable(),
    new_title_dead_id: z.string().optional().nullable(),
    transfer_date: z.string().optional().nullable(),
    is_kyc_done: z.boolean().optional(),
    kyc_proof_id: z.string().optional().nullable(),
    contract_commission_amount: z.string().optional().nullable(),
    is_amount_cash: z.boolean().optional(),

    //Deal Details
    is_ready_with_phpp: z.boolean().optional(),
    is_mortgaged: z.boolean().optional(),
    final_transaction_amount: z.string().nullable().optional(),
    deposit: z.string().nullable().optional(),
    deposit_method: z
      .enum(["cheque", "manager_cheque", "transfer", "cash"])
      .optional()
      .nullable(),
    noc_fee: z.string().nullable().optional(),
    trustee_fees: z.string().nullable().optional(),
    title_deed_fee: z.string().nullable().optional(),
    commission: z.number().nullable().optional(),
    commission_amount: z.string().nullable().optional(),
    vat_of_prima_commission: z.string().nullable().optional(),
    prima_company: z.string().nullable().optional(),
    prima_broker: z.string().nullable().optional(),
    referal: z.string().nullable().optional(),
    other_fees: z
      .array(
        z.object({
          amount: z.number().positive(),
          text: z.string().optional(),
        })
      )
      .optional()
      .nullable(),
    other_agents: z
      .array(
        z.object({
          amount: z.number().positive(),
          text: z.string().optional(),
        })
      )
      .optional()
      .nullable(),
    amount_paid_seller: z.string().nullable().optional(),
    remaining_cheques_id: z.string().optional().nullable(),
    mortgaged_percentage: z.number().nullable().optional(),
    percentage_paid_buyer: z.number().nullable().optional(),
    deal_detail_comment: z.string().optional().nullable(),

    //attachment
    deal_attachment_ids: z.string().array().optional(),
    photos: z.any(),
  })

  // SELLER
  .refine(
    (schema) => {
      if (
        typeof schema.external_property_id === "string" &&
        schema.external_property_id.length > 0 &&
        schema.agent_represent === "seller" &&
        schema.is_company_owner
      ) {
        console.log(
          "seller refine inside if",
          schema.owner_company_id,
          schema.is_company_owner
        );
        return (
          typeof schema.owner_company_id === "string" &&
          schema.owner_company_id.length > 0
        );
      }
      console.log("seller refine", schema.owner_company_id);

      return true;
    },
    {
      path: ["owner_company_id"],
      message: "Prima agent represents the Seller, Company owner a is required",
    }
  )
  .refine(
    (schema) => {
      if (
        typeof schema.external_property_id === "string" &&
        schema.external_property_id.length > 0 &&
        schema.agent_represent === "seller" &&
        !schema.is_company_owner
      ) {
        return (
          typeof schema.owner_id === "string" && schema.owner_id.length > 0
        );
      }
      return true;
    },
    {
      path: ["owner_id"],
      message: "Prima agent represents the Seller, Owner is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "seller") {
        return typeof schema.contract_a_id === "string";
      }
      return true;
    },
    {
      path: ["contract_a_id"],
      message: "Prima agent represents the Seller, Contract A is required.",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "seller") {
        return typeof schema.contract_a_number === "string";
      }
      return true;
    },
    {
      path: ["contract_a_number"],
      message:
        "Prima agent represents the Seller, Contract A Number is required.",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "seller") {
        return typeof schema.contract_a_expiry_date === "string";
      }
      return true;
    },
    {
      path: ["contract_a_expiry_date"],
      message:
        "Prima agent represents the Seller, Contract A Expiry Date is required.",
    }
  )

  // BUYER
  .refine(
    (schema) => {
      if (schema.agent_represent === "buyer" && !schema.is_company_buyer) {
        return (
          typeof schema.buyer_id === "string" && schema.buyer_id.length > 0
        );
      }
      return true;
    },
    {
      path: ["buyer_id"],
      message: "Prima agent represents the Buyer, Buyer is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "buyer" && schema.is_company_buyer) {
        return (
          typeof schema.buyer_company_id === "string" &&
          schema.buyer_company_id.length > 0
        );
      }
      return true;
    },
    {
      path: ["buyer_company_id"],
      message: "Prima agent represents the Buyer, Company owner a is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "buyer") {
        return typeof schema.contract_b_id === "string";
      }
      return true;
    },
    {
      path: ["contract_b_id"],
      message: "Prima agent represents the Buyer, Contract B is required.",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "buyer") {
        return typeof schema.contract_b_number === "string";
      }
      return true;
    },
    {
      path: ["contract_b_number"],
      message:
        "Prima agent represents the Buyer, Contract B Number is required.",
    }
  )
  .refine(
    (schema) => {
      if (schema.agent_represent === "buyer") {
        return typeof schema.contract_b_expiry_date === "string";
      }
      return true;
    },
    {
      path: ["contract_b_expiry_date"],
      message:
        "Prima agent represents the Buyer, Contract B Expiry Date is required.",
    }
  )
  //TO BUY
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return (
          typeof schema.contract_a_number === "string" &&
          schema.contract_a_number.length > 0
        );
      }
      return true;
    },
    {
      path: ["contract_a_number"],
      message: "Contract A number is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return typeof schema.contract_a_expiry_date === "string";
      }
      return true;
    },
    {
      path: ["contract_a_expiry_date"],
      message: "Contract A expiry date is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return (
          typeof schema.contract_b_number === "string" &&
          schema.contract_b_number.length > 0
        );
      }
      return true;
    },
    {
      path: ["contract_b_number"],
      message: "Contract B number is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return typeof schema.contract_f_id === "string";
      }
      return true;
    },
    {
      path: ["contract_f_id"],
      message: "Contract F is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return (
          typeof schema.contract_f_number === "string" &&
          schema.contract_f_number.length > 0
        );
      }
      return true;
    },
    {
      path: ["contract_f_number"],
      message: "Contract F number is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return typeof schema.contract_f_expiry_date === "string";
      }
      return true;
    },
    {
      path: ["contract_f_expiry_date"],
      message: "Contract F expiry date is required",
    }
  )
  .refine(
    (schema) => {
      if (schema.lead_request_for === "to_buy") {
        return (
          typeof schema.contract_password === "string" &&
          schema.contract_password.length > 0
        );
      }
      return true;
    },
    {
      path: ["contract_password"],
      message: "Contract F password is required",
    }
  );

export type DealType = z.infer<typeof DealSchema>;
