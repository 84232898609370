const TotalDealValue = () => {
  return (
    <div className="w-full lg:w-1/2 rounded-lg border borer-[#102448]/[0.60] p-3 flex flex-col gap-6">
      <div className="flex items-start justify-between">
        <h2 className="text-[#102448] font-bold">Referral</h2>
        <span className="flex justify-between items-center pr-1 mt-2 rounded-xl border border-red-600 text-red-600">
          <span className="material-symbols-outlined scale-50 ">
            arrow_downward
          </span>
          <span className="px-1">2.2</span>
        </span>
      </div>
      <p className="text-[#102448]/[0.60] font-bold">45.000.000 AED</p>
    </div>
  );
};

export default TotalDealValue;
