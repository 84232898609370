import { useDispatch, useSelector } from "react-redux";

type DescProp = {
  label: string;
  name: string;
  slice: string;
  object: string;
  field: string;
  action: any;
  className?: string;
  disabled?: boolean;
};

export default function DescriptionField({
  label,
  name,
  action,
  slice,
  object,
  field,
  className,
  disabled = false,
}: DescProp) {
  const dispatch = useDispatch();
  const value = useSelector((state: any) => state[slice][object][field]);
  const validation = useSelector(
    (state: any) => state[slice]["validationErrors"][field]
  );

  return (
    <div>
      <label
        htmlFor={name}
        className={`text-md block mb-2 font-Outfit ${
          validation ? "text-red-500" : "text-[#102448]/[.70]"
        }`}
      >
        {label}
      </label>
      <textarea
        className={`w-full h-[112px] rounded-[15px] border border-[#102448]/[.20] p-3 outline-none resize-none ${className}`}
        name={name}
        id={name}
        value={
          field === "property_description" && value.length >= 750
            ? value.substring(0, 750)
            : value
        }
        disabled={disabled}
        onChange={(e) => {
          dispatch(action({ [field]: e.currentTarget.value }));
        }}
      ></textarea>
    </div>
  );
}
