import { useEffect, useState } from "react";
import FilterButton from "../common/FilterButton";
import SourceofEnquiry from "./reports/SourceofEnquiry";
import TotalCustomerCompany from "./reports/TotalCustomerCompany";
import TotalDealValue from "./reports/TotalDealValue";
import TotalEnquiry from "./reports/TotalEnquiry";
import TotalLeadValue from "./reports/TotalLeadValue";
import TotalListing from "./reports/TotalListing";
import TotalRentValue from "./reports/TotalRentValue";
import TotalSaleValue from "./reports/TotalSaleValue";
import FilterCard from "./reports/filter/FilterCard";
import { hasPermission } from "../../utils/hasPermission";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import FilterChartProperty from "./reports/filter/FilterChartProperty";
import FilterChartEnquiry from "./reports/filter/FilterchartEnquiry";
import FilterChartCustomerCompany from "./reports/filter/FilterChartCustomerCompany";
import FilterChartSourceofEnquiry from "./reports/filter/FilterChartSourceofEnquiry";
import { resetFilter } from "../../store/chart-property";
import TotalRentVolume from "./reports/TotalRentVolume";
import FilterChartRentVolume from "./reports/filter/FilterChartRentVolume";

function Dashboard() {
  const date = new Date();
  console.log(date.getMonth(), "dateeeee");

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, []);
  const [openSearch, setOpenSearch] = useState(false);
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );
  return (
    <div className="px-2">
      <div className="w-full flex flex-col gap-4 mt-7 ">
        <div className="w-full flex justify-between ">
          <h1 className="text-[#102448] text-lg font-bold">CEO Dashboard</h1>
          <FilterButton openSearch={openSearch} setOpenSearch={setOpenSearch} />
        </div>
        {openSearch && <FilterCard />}
        <div className="flex flex-wrap lg:flex-nowrap justify-between gap-3">
          <div className="w-full lg:w-1/2 flex flex-wrap lg:flex-nowrap gap-2">
            {hasPermission(permissions, "Total_Sale_Rent_Value", "reports") && (
              <TotalLeadValue />
            )}
            {hasPermission(permissions, "Total_Sale_Rent_Value", "reports") && (
              <TotalSaleValue />
            )}
          </div>
          <div className="w-full lg:w-1/2 flex flex-wrap lg:flex-nowrap gap-2">
            {hasPermission(permissions, "Total_Sale_Rent_Value", "reports") && (
              <TotalRentValue />
            )}
            {hasPermission(permissions, "Total_Sale_Rent_Value", "reports") && (
              <TotalDealValue />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between">
        {hasPermission(permissions, "Total_Rent_Volume", "reports") && (
          <div className="w-full lg:w-[49%] border rounded p-2 my-2">
            <FilterChartRentVolume />
            <TotalRentVolume />
          </div>
        )}
        {hasPermission(
          permissions,
          "Total Number Of Listing property",
          "reports"
        ) && (
          <div className="w-full lg:w-[49%] border rounded p-2 my-2">
            <FilterChartProperty />
            <TotalListing />
          </div>
        )}
        {hasPermission(
          permissions,
          "Total Number Of company, customer",
          "reports"
        ) && (
          <div className="w-full lg:w-[49%] border rounded p-2 my-2">
            <FilterChartCustomerCompany />
            <TotalCustomerCompany />
          </div>
        )}
        {hasPermission(permissions, "Totla Number Of Enquiry", "reports") && (
          <div className="w-full lg:w-[49%] border rounded p-2 my-2">
            <FilterChartEnquiry />
            <TotalEnquiry />
          </div>
        )}
        {hasPermission(permissions, "Source Of Enquiry", "reports") && (
          <div className="w-full lg:w-[49%] border rounded p-2 my-2">
            <FilterChartSourceofEnquiry />
            <SourceofEnquiry />
          </div>
        )}
      </div>
      {/* <div className="border border-black">
        <div className="flex flex-wrap lg:flex-nowrap gap-4 my-2">
          {hasPermission(permissions, "Total_Rent_Volume", "reports") && (
            <div className="w-full lg:w-1/2 border rounded p-2">
              <FilterChartRentVolume />
              <TotalRentVolume />
            </div>
          )}
          {hasPermission(
            permissions,
            "Total Number Of Listing property",
            "reports"
          ) && (
            <div className="w-full lg:w-1/2 border rounded p-2">
              <FilterChartProperty />
              <TotalListing />
            </div>
          )}
        </div>

        <div className="flex flex-wrap lg:flex-nowrap gap-4 my-2">
          {hasPermission(
            permissions,
            "Total Number Of company, customer",
            "reports"
          ) && (
            <div className="w-full lg:w-1/2 border rounded p-2">
              <FilterChartCustomerCompany />
              <TotalCustomerCompany />
            </div>
          )}
          {hasPermission(permissions, "Totla Number Of Enquiry", "reports") && (
            <div className="w-full lg:w-1/2 border rounded p-2">
              <FilterChartEnquiry />
              <TotalEnquiry />
            </div>
          )}
        </div>
        <div className="flex flex-wrap lg:flex-nowrap gap-4 my-2 border">
          {hasPermission(permissions, "Source Of Enquiry", "reports") && (
            <div className="w-full lg:w-1/2 border rounded p-2">
              <FilterChartSourceofEnquiry />
              <SourceofEnquiry />
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
