import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

type SelectProps = {
  label: string;
  slice: string;
  object: string;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any;
  title?: string;
  data: { title: string; value: string | boolean }[];
  disabled?: boolean;
};
export default function Select({
  label,
  title,
  slice,
  object,
  field,
  action,
  data,
  disabled
}: SelectProps) {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  let titleField = "title";
  if (title) titleField = title;

  const filteredData =
    query === ""
      ? data
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?.filter((person: any) =>
          person[titleField]
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const validation = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state[slice]["validationErrors"][field]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = useSelector((state: any) => state[slice][object][field]);
  const selected = data?.find((item) => item.value === value) || null;

  return (
    <div className="w-full">
      <label
        className={`text-sm block mb-2 font-Outfit ${
          validation ? "text-red-500" : "text-[#102448]/[.70]"
        }`}
      >
        {label}
      </label>
      <Combobox
        disabled={disabled}
        value={selected}
        onChange={(selected) => {
          dispatch(action({ [field]: selected?.value }));
        }}
      >
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm border border-[#102448]/[0.20]">
            <Combobox.Input
              className="w-full border-none pl-3 pr-10 text-sm leading-5 text-[#102448] focus:ring-0 outline-none py-3"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              displayValue={(person: any) => person?.[titleField]}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-[#102448]"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base  ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredData?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-[#102448]">
                  Nothing found.
                </div>
              ) : (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filteredData?.map((item: any, index) => (
                  <Combobox.Option
                    key={item.title || index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-6 pr-2 ${
                        active ? "bg-dark-blue text-white" : "text-[#102448]"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal text-sm"
                          }`}
                        >
                          {item[titleField]}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-1 ${
                              active
                                ? "text-white bg-dark-blue"
                                : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
