import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../app/store";

import CompareFilter from "./CompareFilter";
import RefreshButton from "../../../common/RefreshButton";
import {
  resetFilter,
  setEndDate,
  setStartDate,
} from "../../../../store/rent-sale-card";

const FilterCard = () => {
  const dispatch = useDispatch();

  const startDate = useSelector(
    (state: RootState) => state.rentandsalecard.filter.start_date
  );
  const endDate = useSelector(
    (state: RootState) => state.rentandsalecard.filter.end_date
  );

  return (
    <div className="w-full items-end rounded-lg border border-[#102448]/[0.20]  shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)] mt-3 px-3 py-5 flex flex-wrap gap-6 bg-white top-[100%] transition-all duration-500">
      <div className="">
        <label className="text-md block mb-2 font-Outfit text-[#102448]/[.70]">
          Date Range
        </label>
        <Datepicker
          primaryColor="blue"
          value={{ startDate, endDate }}
          onChange={(value: DateValueType) => {
            if (value?.startDate) {
              dispatch(setStartDate(value.startDate));
            }
            if (value?.endDate) {
              dispatch(setEndDate(value.endDate));
            }
          }}
          showShortcuts={true}
        />
      </div>
      <CompareFilter />
      <div className="w-full flex justify-end">
        <RefreshButton
          onClick={() => {
            dispatch(resetFilter());
          }}
        />
      </div>
    </div>
  );
};

export default FilterCard;
