import { useDispatch, useSelector } from "react-redux";

type TextProp = {
  type: string;
  label: string;
  name: string;
  field: string;
  slice: string;
  object: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any;
  disabled?: boolean;
  autoComplete?: string;
  price?: boolean;
};

export default function TextField({
  type,
  label,
  name,
  field,
  slice,
  object,
  action,
  disabled,
  autoComplete = "off",
  price = false,
}: TextProp) {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = useSelector((state: any) => state[slice][object][field]);

  const validation = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state[slice]["validationErrors"][field]
  );

  return (
    <div className="flex flex-wrap w-full">
      <label
        htmlFor={name}
        className={`w-full text-md block mb-2 ${
          validation ? "text-red-500" : "text-[#102448]/[.70]"
        }`}
      >
        {label}
      </label>
      <input
        className="w-full h-11 rounded-md border border-[#102448]/[.20] p-5 outline-none"
        type={type}
        name={name}
        id={name}
        autoComplete={autoComplete}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          let data: string | number | null = e.currentTarget.value;
          if (type === "number" && e.currentTarget.value === "") {
            data = null;
          } else if (type === "number") {
            data = e.currentTarget.valueAsNumber;
          }
          dispatch(action({ [field]: data }));
        }}
      />
      <div className="my-1 pl-2 text-xs text-blue-500">
        {Number(value)
          ? `${price && value ? parseInt(value).toLocaleString() + " AED" : ""}`
          : ""}
      </div>
    </div>
  );
}
