import ReactDOM from "react-dom/client";
import "./css/index.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import { showErrorMessage } from "./helpers/alert";
import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e58b3d90b0c9ba81e8210f69cfe0a258@o4507400857059328.ingest.us.sentry.io/4507400861057024",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("getting unauthorized");
      localStorage.removeItem("token");
      location.href = "/login";
    }
    let message = error.message;
    if (error.response?.data?.errorDetails?.message) {
      message = error.response?.data?.errorDetails?.message;
    }
    showErrorMessage(message);
    throw error;
  }
);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={client}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <>
          <RouterProvider router={router} />
          <Toaster gutter={1} position="bottom-left" />
        </>
      </Provider>
    </Suspense>
  </QueryClientProvider>
);
