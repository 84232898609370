import axios from "axios";

export async function login(email: string, password: string) {
  return axios.post(
    "auth/login",
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export async function getMe() {
  return axios.get("user/me");
}
