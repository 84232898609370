import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setLead } from "../../store/leadSlice";
type SeeMoreProps = {
  entityId?: string;
  type: string;
  seen: boolean;
};
const SeeMore = ({ entityId, type, seen }: SeeMoreProps) => {
  const dispatch = useDispatch();
  const seeMoreLinkHandler = (type: string) => {
    if (type == "assign_enquiry" || type == "unassign_enquiry") {
      return entityId ? `/enquiries/${entityId}` : "#";
    }
    if (
      type == "add_sitevisit" ||
      type == "add_followup" ||
      type == "deal_transfer_date"
    ) {
      return entityId ? `/leads/${entityId}` : "#";
    }
    return "";
  };
  const seeMoreOnClickHandler = (type: string) => {
    if (type == "add_sitevisit") {
      dispatch(setLead({ tab: 1 }));
    }
    if (type == "add_followup") {
      dispatch(setLead({ tab: 2 }));
    }
    if (type == "deal_transfer_date") {
      dispatch(setLead({ tab: 3 }));
    }
  };
  return (
    <Link
      to={seeMoreLinkHandler(type)}
      onClick={()=>seeMoreOnClickHandler(type)}
      className="flex items-end"
    >
      <span
        title="see more"
        className={`material-symbols-outlined  ${
          seen ? "text-[#102448]/[0.60]" : " text-[#102448]"
        }`}
      >
        read_more
      </span>
    </Link>
  );
};

export default SeeMore;
