const FilterButton = ({
  openSearch,
  setOpenSearch,
}: {
  openSearch: boolean;
  setOpenSearch: (open: boolean) => void;
}) => {
  return (
    <span
      title="filter"
      className={`h-full rounded  flex justify-center items-center p-2  cursor-pointer transition-all ${
        openSearch
          ? "bg-[#F8B113] text-[#FEF7EB]"
          : "bg-[#FEF7EB] text-[#F8B113]"
      }`}
      onClick={() => {
        setOpenSearch(!openSearch);
      }}
    >
      <span className="material-symbols-outlined">page_info</span>
    </span>
  );
};

export default FilterButton;
