import axios, { AxiosProgressEvent } from "axios";
import { FileType } from "../types/fileType";
import { downloadFileHelper } from "./downloadFileHelperApi";

export async function getProperties(qParams: string) {
  const res = await axios.get(`properties${qParams}`);

  return res.data;
}

export async function getProperty(id: string) {
  return await axios.get(`properties/${id}`);
}

export async function getPropertyBrochure(id: string) {
  return await axios.get(`properties/brochure/${id}`);
}

export async function exportExcel() {
  const response = await axios.get("properties/exportexcel", {
    responseType: "arraybuffer",
  });

  const filePath = "properties.xlsx";
  const data = response.data;
  const type = response.headers["Content-Type"];
  downloadFileHelper({ data, filePath, type });
}

export async function removeProperty(id?: string): Promise<void> {
  return await axios.delete(`properties/${id}`);
}

export async function getAreas() {
  return await axios.get("area");
}
export async function removeArea(id: string): Promise<void> {
  return await axios.delete(`area/${id}`);
}
export async function getView() {
  return await axios.get("property-view");
}
export async function removePropertyView(id: string): Promise<void> {
  return await axios.delete(`property-view/${id}`);
}
export async function getFeature() {
  return await axios.get("feature");
}
export async function removeFeature(id: string): Promise<void> {
  return await axios.delete(`feature/${id}`);
}
export async function getPropertytype(take = 20) {
  return await axios.get(`property-type?take=${take}`);
}
export async function removePropertytype(id: string): Promise<void> {
  return await axios.delete(`property-type/${id}`);
}
export async function uploadFile({
  id,
  file,
  setProgress,
}: {
  id: string;
  file: File;
  setProgress?: React.Dispatch<React.SetStateAction<number>>;
}) {
  return axios.post(
    "file/upload",
    {
      id: id,
      file: file,
    },

    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (setProgress && progressEvent?.total) {
          setProgress(progressEvent.loaded / progressEvent.total);
        }
      },
    }
  );
}

export async function downloadFile(file: FileType): Promise<void> {
  const response = await axios.get(`file/download/${file.filename}`, {
    responseType: "blob",
  });
  const filePath = file.filename + "." + file.mimetype.split("/")[1];
  const data = response.data;
  downloadFileHelper({ data, filePath });
}

export async function addComment({
  text,
  property_id,
}: {
  text: string;
  property_id?: string;
}) {
  return axios.post(
    "comment",

    {
      text: text,
      property_id: property_id,
    },

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export async function archiveProperty(id: string, is_archive: boolean) {
  return axios.put(
    `properties/archive/${id}`,
    {
      is_archive,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
