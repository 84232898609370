import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import SettingSideLink from "./SettingSideLink";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../../utils/hasPermission";
import { RootState } from "../../../app/store";
import { Suspense, useEffect, useRef, useState } from "react";
import ProfileResponsive from "../../common/ProfileResponsive";
import { useQueryClient } from "@tanstack/react-query";
import Profile from "../../common/Profile";
import { setToken } from "../../../store/loginSlice";
import ListCounterUnRead from "../../inbox/ListCountUnread";

const SettingLayout = () => {
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const sideBar = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const location = useLocation();
  useEffect(() => {
    const title = location.pathname.split("/")[2];
    const pertitle = location.pathname.split("/")[1];
    document.title = ` ${pertitle[0].toUpperCase() + pertitle.slice(1)} >>
     ${title[0].toUpperCase() + title.slice(1)}`;
  }, [location]);
  useEffect(() => {
    return () => {
      document.title = `Prima CRM `;
    };
  }, []);
  const token = useSelector((state: RootState) => state.login.token);
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );

  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="w-full h-screen flex flex-col ">
      <div className="flex flex-1">
        <div
          ref={sideBar}
          className="hidden lg:flex w-56 flex-col bg-[#102448] pt-5"
        >
          <div className="w-full">
            <div className="flex items-end justify-center">
              <div className="flex flex-col gap-5">
                <img className="mx-auto" src="/logo_mobile.png" alt="" />
                <h2 className="flex gap-2 flex-1 justify-center items-center text-white text-xl pr-2 cursor-default">
                  <Link
                    to="/"
                    className="flex items-center text-white cursor-pointer border rounded-full hover:bg-[#00000044] hover:border-[#102448]"
                  >
                    <span className="material-symbols-outlined">
                      arrow_back
                    </span>
                  </Link>
                  Settings
                </h2>
              </div>
            </div>
          </div>
          <div className="w-full items-start justify-end flex flex-1">
            <ul className="flex flex-wrap justify-end content-center mt-20">
              {hasPermission(permissions, "Add", "user") && (
                <SettingSideLink
                  href="/settings/users"
                  text="Users"
                  icon="support_agent"
                  isActive={location.pathname.startsWith("/settings/users")}
                />
              )}
              {hasPermission(permissions, "Read", "role") && (
                <SettingSideLink
                  href="/settings/roles"
                  text="Roles"
                  icon="settings_accessibility"
                  isActive={location.pathname.startsWith("/settings/roles")}
                />
              )}
              <SettingSideLink
                href="/settings/defaults"
                text="Defaults"
                icon="published_with_changes"
                isActive={location.pathname.startsWith("/settings/defaults")}
              />
            </ul>
          </div>
        </div>

        <div className="px-7 pt-3 flex-1">
          <div className="w-full flex items-center px-3 py-1 rounded shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)] relative">
            <div
              className="w-12  cursor-pointer flex justify-center"
              onClick={() => {
                if (sideBar.current) {
                  if (window.innerWidth > 992) {
                    sideBar.current.classList.toggle("lg:flex");
                  } else {
                    sideBar.current.classList.remove("lg:flex");
                    sideBar.current.classList.toggle("hidden");
                  }
                }
              }}
            >
              <span className="material-symbols-outlined text-[#102448] items-center">
                sort
              </span>
            </div>
            <div className="grow-[3] flex items-center pl-4">
              <div className="flex-1"></div>

              {hamburgerMenu ? <span className="h-12"></span> : <Profile />}
              <span className="relative">
                <span
                  onClick={() => {
                    navigate("/inbox");
                  }}
                  className="material-symbols-outlined cursor-pointer   text-[#102448] relative"
                >
                  notifications
                </span>
                <ListCounterUnRead />
              </span>

              <span
                className={`flex text-[#102448] p-2 hover:bg-[#102448]/[0.20] rounded cursor-pointer ${
                  hamburgerMenu ? "bg-[#102448]/[0.20]" : "bg-transparent"
                }`}
                onClick={() => {
                  setHamburgerMenu(!hamburgerMenu);
                }}
              >
                <span className="material-symbols-outlined ">for_you</span>
              </span>
              <div
                className={`absolute right-0 top-[90%] w-fit z-20 flex flex-col gap-2 rounded p-3 bg-white shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)]    transition-all ${
                  hamburgerMenu ? `flex` : `hidden`
                }`}
              >
                <div className="flex gap-1 justify-center items-center">
                  <ProfileResponsive />
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={async () => {
                      localStorage.removeItem("token");
                      await queryClient.removeQueries();
                      dispatch(setToken(null));
                    }}
                    className="bg-[#102448] text-sm text-white rounded-md p-2"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <Toaster gutter={1} position="bottom-left" />
            <Suspense fallback={<div></div>}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingLayout;
