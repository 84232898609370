import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../../common/Upload";
import { setDeal } from "../../../../../store/deal-slice";
import { RootState } from "../../../../../app/store";

const UploadRemainingCheques = () => {
  const dispatch = useDispatch();
  const remaining_cheques = useSelector(
    (state: RootState) => state.deal.deal.remaining_cheques
  );

  return (
    <div className="flex flex-col justify-between">
      <label className={"justify-self-end text-[#102448]/[.70] mb-1"}>
        Remaining Cheques
      </label>
      <div className="w-full">
        <Upload
          name="remainingCheques"
          file={remaining_cheques}
          onUpload={(
            remaining_cheques_id: string,
            url: string,
            filename: string
          ) =>
            dispatch(
              setDeal({
                remaining_cheques_id,
                remaining_cheques: {
                  url: url,
                  filename: filename,
                  createdAt: new Date(),
                },
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default UploadRemainingCheques;
