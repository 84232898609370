// SiteVisit
export const siteVisitStatusData = [
  { value: "pending", title: "Pending" },
  { value: "completed", title: "Completed" },
  { value: "to do", title: "To Do" },
];
// FollowUp
export const followUpStatusData = [
  { value: "pending", title: "Pending" },
  { value: "completed", title: "Completed" },
  { value: "to do", title: "To Do" },
];

export type proceedDealDataType = {
  lead_id?: string;
  property_id?: string;
  external_property_id?: string;
};
// Deal-Contract
export const agentRepresent = [
  { value: "buyer", title: "Buyer" },
  { value: "seller", title: "Seller" },
  { value: "both", title: "Both" },
];
export const amountData = [
  { value: true, title: "Cash" },
  { value: false, title: "Cheque" },
];
//Deal-RentContract
export const agentRepresentForRent = [
  { value: "tenant", title: "Tenant" },
  { value: "renter", title: "Renter" },
  { value: "both", title: "Both" },
];
// Deal Detail
export const DealDetailPHPPStep = [
  { value: true, title: "ready with PHPP" },
  { value: false, title: "ready without PHPP" },
];

export const DealDetailMortgagedStep = [
  { value: true, title: "mortgaged" },
  { value: false, title: "cash buyer" },
];

export const DepositeMethod = [
  { value: "cheque", title: "Cheque" },
  { value: "manager cheque", title: "Manager cheque" },
  { value: "transfer", title: "Transfer" },
  { value: "cash", title: "Cash" },
];
