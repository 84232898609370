import { createSlice } from "@reduxjs/toolkit";
import { CommentType } from "../validation/comment";

const initialComment = {
  text: "",
  recipient_ids: [],
  propertyId: "",
  search: "",
};
export interface CommentState {
  filter: {
    search: string;
  };
  validationErrors: Partial<CommentType>;
  comment: CommentType;
}
const initialFilter = {
  search: "",
};
const initialState: CommentState = {
  filter: initialFilter,
  validationErrors: {},
  comment: initialComment,
};
export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setComment: (state, action) => {
      state.comment = { ...state.comment, ...action.payload };
    },
    clearComment: (state) => {
      state.comment = initialComment;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
  },
});
export const {
  setSearch,
  setComment,
  clearComment,
  setValidationErrors,
  resetValidationErrors,
  resetFilter,
} = commentSlice.actions;
export default commentSlice.reducer;
