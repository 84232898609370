type RefreshButtonProp = {
  onClick: () => void;
};
const RefreshButton = ({ onClick }: RefreshButtonProp) => {
  return (
    <button
      className="flex justify-center items-center p-1 bg-[#102448]/[0.60]  text-white rounded "
      onClick={onClick}
    >
      <span className="material-symbols-outlined">refresh</span>
    </button>
  );
};

export default RefreshButton;
