import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../../common/Upload";
import { setDeal } from "../../../../../store/deal-slice";
import { RootState } from "../../../../../app/store";

const DeadUpload = () => {
  const dispatch = useDispatch();
  const new_title_dead = useSelector(
    (state: RootState) => state.deal.deal.new_title_dead
  );
  return (
    <div className="flex flex-col justify-between">
      <label className={"justify-self-end text-[#102448]/[.70] mb-1"}>
        New Title Deed
      </label>
      <div className="w-full">
        <Upload
          name="newtitledead"
          file={new_title_dead}
          onUpload={(
            new_title_dead_id: string,
            url: string,
            filename: string
          ) =>
            dispatch(
              setDeal({
                new_title_dead_id,
                new_title_dead: {
                  url: url,
                  filename: filename,
                  createdAt: new Date(),
                },
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default DeadUpload;
