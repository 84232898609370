import { useDispatch, useSelector } from "react-redux";
import { getMe, login } from "../../api/login/loginApi";
import { setProfile, setToken } from "../../store/loginSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RootState } from "../../app/store";

const LoginButton = () => {
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.login.login.email);
  const password = useSelector(
    (state: RootState) => state.login.login.password
  );
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="p-3 outline-none rounded  bg-[#102448] text-[#FEF7EB]"
      onClick={async () => {
        const token = await login(email, password);
        axios.defaults.headers.common[
          "Authorization"
        ] = `bearer ${token.data.token}`;
        dispatch(setToken(token.data.token));
        const { data } = await getMe();

        dispatch(setProfile(data));
        navigate("/");
      }}
    >
      Login
    </button>
  );
};

export default LoginButton;
