import React from "react";

type ConfirmMessageProps = {
  id?: string;
  message: string;
  onConfirm: (id?: string) => void;
  onCancel: () => void;
};

const ConfirmMessage: React.FC<ConfirmMessageProps> = ({
  id,
  message,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white border border-gray-300 shadow-md rounded-md p-4">
        <p className="text-dark-blue text-lg mb-2">{message}</p>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 mr-2 bg-dark-blue hover:bg-prima-brown text-white rounded-md"
            onClick={() => onConfirm(id)}
          >
            Yes
          </button>
          <button
            className="text-dark-blue px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-md"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmMessage;
