import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getSourceofEnquiry } from "../../../api/dashboard/reports";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

const options = {
  chart: {
    type: "pie",
  },
  title: {
    text: "Source of Enquiry",
  },
  tooltip: {
    valueSuffix: "%",
  },
  //   subtitle: {
  //     text: 'Source:<a href="https://www.mdpi.com/2072-6643/11/3/684/htm" target="_default">MDPI</a>',
  //   },
  plotOptions: {
    series: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: [
        {
          enabled: true,
          distance: 20,
        },
        {
          enabled: true,
          distance: -40,
          format: "{point.percentage:.1f}%",
          style: {
            fontSize: "1.2em",
            textOutline: "none",
            opacity: 0.7,
          },
          filter: {
            operator: ">",
            property: "percentage",
            value: 10,
          },
        },
      ],
    },
  },
  series: [
    {
      name: "Percentage",
      colorByPoint: true,
      data: [],
    },
  ],
};
function SourceofEnquiry() {
  const sourceofEnquiryYear = useSelector(
    (state: RootState) => state.chartproperty.filter.sourceofEnquiryYear
  );

  const queries = [];
  if (sourceofEnquiryYear)
    queries.push(
      `year=${
        new Date(sourceofEnquiryYear).getFullYear() +
        "-" +
        new Date(sourceofEnquiryYear).getMonth() +
        "-" +
        new Date(sourceofEnquiryYear).getDate()
      }`
    );
  const params = queries.length ? "?" + queries.join("&") : "";
  console.log("queries", queries);
  const { data, isLoading } = useQuery({
    queryKey: ["Sourceofenquiry", sourceofEnquiryYear],
    queryFn: async () => await getSourceofEnquiry(params),
  });
  if (isLoading) {
    return "";
  }
  console.log(data, "source of enquiry");

  options.series.map((item) => {
    item.data = data?.data.map((i: { name: string; y: number }) => ({
      name: i.name,
      y: i.y,
    }));
  });
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
export default SourceofEnquiry;
