import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import {
  resetFilter,
  setEndDate,
  setSearch,
  setStartDate,
} from "../../store/user-activity-slice";
import { useDispatch, useSelector } from "react-redux";
import RefreshButton from "../common/RefreshButton";
import { RootState } from "../../app/store";

const ActivityFilter = () => {
  const dispatch = useDispatch();
  const search = useSelector(
    (state: RootState) => state.useractivity.filter.search
  );

  const startDate = useSelector(
    (state: RootState) => state.useractivity.filter.start_date
  );
  const endDate = useSelector(
    (state: RootState) => state.useractivity.filter.end_date
  );

  return (
    <div className="w-full items-end rounded-lg border border-[#102448]/[0.20]  shadow-[0_1px_10px_-1px_rgba(0,0,0,0.1)] mt-3 px-3 py-5 flex flex-wrap gap-6 bg-white top-[100%] transition-all duration-500">
      <div className="w-1/3">
        <label className="text-md block mb-2 font-Outfit text-[#102448]/[.70]">
          Search
        </label>
        <input
          type="text"
          className="w-full p-2 border border-[#102448]/[0.20] rounded bg-[#102448]/[0.02] text-[#102448]/[0.20] outline-none"
          placeholder="Search..."
          value={search}
          onChange={(e) => dispatch(setSearch(e.target.value))}
        />
      </div>
      <div className="">
        <label className="text-md block mb-2 font-Outfit text-[#102448]/[.70]">
          Date Range
        </label>
        <Datepicker
          primaryColor="blue"
          value={{ startDate, endDate }}
          onChange={(value: DateValueType) => {
            if (value?.startDate) {
              dispatch(setStartDate(value.startDate));
            }
            if (value?.endDate) {
              dispatch(setEndDate(value.endDate));
            }
          }}
          showShortcuts={true}
        />
      </div>
      <div className="flex-1"></div>
      <div className="flex justify-end">
        <RefreshButton
          onClick={() => {
            dispatch(resetFilter());
          }}
        />
      </div>
    </div>
  );
};

export default ActivityFilter;
