import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDeal } from '../../../../store/deal-slice';

interface OtherFee {
  amount: number;
  text: string;
}

interface OtherFeesProps {
  label: string;
  fieldName: string;
  slice: string;
  object: string;
}

const OtherFees: React.FC<OtherFeesProps> = ({ label, fieldName, slice, object }) => {
  const dispatch = useDispatch();
  const otherFees = useSelector((state: any) => state[slice][object][fieldName]) || [];

  const [newFee, setNewFee] = useState<OtherFee>({ amount: 0, text: '' });
  const [fees, setFees] = useState<OtherFee[]>(otherFees);

  const handleAddFee = () => {
    if(newFee.amount !== 0 && newFee.text !== ''){
        setFees([...fees, newFee]);
        dispatch(setDeal({ [fieldName]: [...fees, newFee] }));
        setNewFee({ amount: 0, text: '' });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewFee({ ...newFee, [name]: Number(value) || value });
  };

  const handleFeeChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedFees = [...fees];
    updatedFees[index] = { ...updatedFees[index], [name]: Number(value) || value };
    setFees(updatedFees);
    dispatch(setDeal({ [fieldName]: updatedFees }));
  };

  const handleFeeDelete = (index: number) => {
    const updatedFees = [...fees];
    updatedFees.splice(index, 1);
    setFees(updatedFees);
    dispatch(setDeal({ [fieldName]: updatedFees }));
  };

  return (
    <div className="flex flex-col gap-2">
      <label className="w-full text-md block mb-2">{label}</label>
      {fees.map((fee, index) => (
        <>
        <div key={index} className="flex gap-2 items-center">
            <input
                type="number"
                placeholder="Amount"
                name="amount"
                value={fee.amount}
                onChange={(e) => handleFeeChange(index, e)}
                className="w-full h-11 rounded-md border border-[#102448]/[.20] p-5 outline-none"
            />
          
            <input
                type="text"
                placeholder="Text"
                name="text"
                value={fee.text}
                onChange={(e) => handleFeeChange(index, e)}
                className="w-full h-11 rounded-md border border-[#102448]/[.20] p-5 outline-none"
            />
            <button
                type="button"
                onClick={() => handleFeeDelete(index)}
                className="w-20 h-7 flex justify-center items-center my-auto rounded-lg  border border-[#102448]/[0.20] text-md bg-[#102448] text-white"
            >
                <span className="material-symbols-outlined scale-50">delete</span>
            </button>
        </div>
        <div className="my-1 pl-2 text-xs text-blue-500">
            {fee.amount ? fee.amount.toLocaleString() + " AED" : ""}
        </div>
        </>
      ))}
        <div className="flex gap-2">
            <input
                type="number"
                placeholder="Amount"
                name="amount"
                value={newFee.amount}
                onChange={handleInputChange}
                className="w-full h-11 rounded-md border border-[#102448]/[.20] p-5 outline-none"
            />
            <input
                type="text"
                placeholder="Text"
                name="text"
                value={newFee.text}
                onChange={handleInputChange}
                className="w-full h-11 rounded-md border border-[#102448]/[.20] p-5 outline-none"
            />
            <button type="button" onClick={handleAddFee} className="w-20 h-7 flex justify-center items-center my-auto rounded-lg  border border-[#102448]/[0.20] text-md bg-[#102448] text-white">
                <span className="material-symbols-outlined scale-50">add</span>
            </button>
        </div>
        <div className="flex flex-row my-1 pl-2 text-xs text-blue-500">
                {newFee.amount ? newFee.amount.toLocaleString() + " AED" : ""}
        </div>
    </div>
  );
};

export default OtherFees;
